import {
  createCandidateNclex,
  createCandidateNclexAssessmentTest,
  createCandidateNclexTest,
  createCandidatePearson,
  deleteCandidateNclexAssessmentTest,
  deleteCandidateNclexTest,
  deleteCandidatePearson,
  setErrors,
  setFailed,
  updateCandidateNclex,
  updateCandidateNclexAssessmentTest,
  updateCandidateNclexTest,
  updateCandidatePearson,
} from 'reducers/candidatesSlice';

import {
  invalidRecords, prepareModifyData,
} from 'Util';

// eslint-disable-next-line import/prefer-default-export
export const saveNCLEX = (
  t,
  dispatch,
  nclex,
  pearsonRegistrations,
  nclexAssessmentTests,
  nclexTests,
  candidateId,
) => {
  if (nclex.valid !== false
    && !invalidRecords(nclexTests).length > 0
    && !invalidRecords(nclexAssessmentTests).length > 0
    && !invalidRecords(pearsonRegistrations).length > 0) {
    const tests = nclexTests.filter((item) => item.changed === true);
    const assessments = nclexAssessmentTests.filter((item) => item.changed === true);
    const pearson = pearsonRegistrations.filter((item) => item.changed === true);

    if (nclex.changed || tests.length > 0 || assessments.length > 0 || pearson.length > 0) {
      prepareModifyData(tests).map((e) => {
        const data = {
          candidateId,
          schedule: e.schedule,
          nclexResultId: e.nclex_result_id,
          countryId: e.country_id,
          attNumber: e.att_number,
          attReceived: e.att_received,
          attExpiration: e.att_expiration,
        };

        // eslint-disable-next-line no-underscore-dangle
        if (e._destroy === true) {
          dispatch(deleteCandidateNclexTest({ candidateId, id: e.id }));
        } else if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidateNclexTest(data));
        } else {
          dispatch(createCandidateNclexTest(data));
        }

        return true;
      });

      prepareModifyData(assessments).map((e) => {
        const data = {
          candidateId,
          date: e.date,
          score: e.score,
          result: e.result,
          challengingTopicId: e.challenging_topic_id,
          nclexReadinessId: e.nclex_readiness_id,
          typeTest: e.type_test,
        };

        // eslint-disable-next-line no-underscore-dangle
        if (e._destroy === true) {
          dispatch(deleteCandidateNclexAssessmentTest({ candidateId, id: e.id }));
        } else if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidateNclexAssessmentTest(data));
        } else {
          dispatch(createCandidateNclexAssessmentTest(data));
        }

        return true;
      });

      prepareModifyData(pearson).map((e) => {
        const data = {
          candidateId,
          user: e.user,
          password: e.password,
          registrationDate: e.registration_date,
          ncsbnId: e.ncsbn_id,
          paymentDate: e.payment_date,
        };

        // eslint-disable-next-line no-underscore-dangle
        if (e._destroy === true) {
          dispatch(deleteCandidatePearson({ candidateId, id: e.id }));
        } else if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidatePearson(data));
        } else {
          dispatch(createCandidatePearson(data));
        }

        return true;
      });

      const data = {
        candidateId,
        archerRegistrationDate: nclex.archer_registration_date,
        archerExpirationDate: nclex.archer_expiration_date,
        archerUsername: nclex.archer_username,
        archerPassword: nclex.archer_password,
        saundersRegistrationDate: nclex.saunders_registration_date,
        saundersExpirationDate: nclex.saunders_expiration_date,
        saundersUsername: nclex.saunders_username,
        saundersPassword: nclex.saunders_password,
        expectedDate: nclex.expected_date,
      };

      if (nclex.changed) {
        if (typeof nclex.id !== 'undefined') {
          data.id = nclex.id;
          dispatch(updateCandidateNclex(data));
        } else {
          dispatch(createCandidateNclex(data));
        }
      }
    } else {
      dispatch(setErrors(t('no-changes-to-save', { keyPrefix: 'common' })));
      dispatch(setFailed(true));
    }
  } else {
    dispatch(setErrors(t('entry-is-required-or-has-an-invalid-value', { keyPrefix: 'common' })));
    dispatch(setFailed(true));
  }
};
