import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftEditor from 'components/SoftEditor';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { NumericFormat } from 'react-number-format';
import {
  classicSubjects,
  defaultValue,
  findSelectValue,
  ngnSubjects,
} from 'Util';

function CoachingFollowUp({
  metadata = { total: 0 },
  setValue,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.events' });
  const subjectsNgn = ngnSubjects(t);
  const subjectsClassic = classicSubjects(t);

  return (
    <div id="coaching">
      <Card id="answered-questions" sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('answered-questions')}
        />
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={1}
          >
            <Grid item md={2}>
              <FormField
                label={t('total')}
                error={!metadata.total || metadata.total === ''}
                value={defaultValue(metadata.total)}
                onChange={(e) => {
                  setValue('total', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormField
                label={t('since-last-meeting')}
                error={!metadata.since_last_meeting || metadata.since_last_meeting === ''}
                value={defaultValue(metadata.since_last_meeting)}
                onChange={(e) => {
                  setValue('since_last_meeting', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormField
                label={t('expected-since-last-meeting')}
                error={!metadata.expected_since_last_meeting || metadata.expected_since_last_meeting === ''}
                value={defaultValue(metadata.expected_since_last_meeting)}
                onChange={(e) => {
                  setValue('expected_since_last_meeting', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormField
                label={t('total-correct-ngn')}
                error={!metadata.total_correct_ngn || metadata.total_correct_ngn === ''}
                value={defaultValue(metadata.total_correct_ngn)}
                onChange={(e) => {
                  setValue('total_correct_ngn', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormField
                label={t('total-correct-classic')}
                error={!metadata.total_correct_classic || metadata.total_correct_classic === ''}
                value={defaultValue(metadata.total_correct_classic)}
                onChange={(e) => {
                  setValue('total_correct_classic', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <NumericFormat
                label={t('pct-questions-answered')}
                error={!metadata.pct_questions_answered || metadata.pct_questions_answered === ''}
                value={defaultValue(metadata.pct_questions_answered)}
                onChange={(e) => {
                  setValue('pct_questions_answered', e.target.value);
                }}
                customInput={FormField}
                thousandSeparator
                isNumericString
                suffix="%"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card id="improvement" sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('improvement')}
        />
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={1}
          >
            <Grid item md={4}>
              <FormSelect
                label={t('subject-ngn')}
                options={subjectsNgn}
                error={metadata.subject_ngn_id
                  ? false : !metadata.subject_ngn_id || metadata.subject_ngn_id === ''}
                value={findSelectValue(subjectsNgn, metadata.subject_ngn_id)}
                onChange={(e) => {
                  setValue('subject_ngn_id', e.value);
                }}
              />
            </Grid>
            <Grid item md={4}>
              <FormSelect
                label={t('subject-classic')}
                options={subjectsClassic}
                error={metadata.subject_classic_id
                  ? false : !metadata.subject_classic_id || metadata.subject_classic_id === ''}
                value={findSelectValue(subjectsClassic, metadata.subject_classic_id)}
                onChange={(e) => {
                  setValue('subject_classic_id', e.value);
                }}
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={12}>
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t('plan')}
                  </SoftTypography>
                </SoftBox>
                { }
                <SoftEditor
                  value={metadata.plan}
                  onChange={(newContent) => setValue('plan', newContent)}
                />
              </SoftBox>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

CoachingFollowUp.propTypes = {
  // eslint-disable-next-line react/require-default-props
  metadata: PropTypes.shape({
    total: PropTypes.number,
    since_last_meeting: PropTypes.number,
    total_correct_ngn: PropTypes.number,
    expected_since_last_meeting: PropTypes.number,
    total_correct_classic: PropTypes.number,
    pct_questions_answered: PropTypes.number,
    subject_ngn_id: PropTypes.number,
  }),
  setValue: PropTypes.func.isRequired,
};

export default CoachingFollowUp;
