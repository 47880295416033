import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducers
import { fetchContactMethods, fetchMeetingOutcomes, fetchMeetingTypes } from 'reducers/commonSlice';
import { fetchStaffInfo } from 'reducers/usersSlice';
import {
  createCandidateEvent, deleteCandidateEvent,
  fetchCandidateEvents,
  setCreated,
  setSaved,
  updateCandidateEvent,
} from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Functions
import { findSelectValue, getCandidateUUID } from 'Util';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

// Components
import FormSelect from 'layouts/components/FormSelect';
import EventInfoCard from './components/EventInfoCard';
import MeetingEvent from './components/MeetingEvent';
import CommunicationEvent from './components/CommunicationEvent';
import ReminderEvent from './components/ReminderEvent';

const selector = (state) => ({
  editing: state.candidate.editing,
  created: state.candidate.created,
  saved: state.candidate.saved,
  profile: state.candidate.profile,
  currentUser: state.auth.user,
  events: state.candidate.events,
  staff: state.user.staff,
  meetingTypes: state.common.meetingTypes,
  meetingOutcomes: state.common.meetingOutcomes,
  contactMethods: state.common.contactMethods,
});

function Events() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.events' });
  const {
    editing,
    created,
    saved,
    profile,
    currentUser,
    events,
    staff,
    meetingTypes,
    meetingOutcomes,
    contactMethods,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();
  const { height } = useWindowDimensions();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [parentHeight, setParentHeight] = useState(500);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState(0);
  const [selectedMeetingType, setSelectedMeetingType] = useState(0);
  const [availableMeetingTypes, setAvailableMeetingTypes] = useState([]);
  const [eventInfo, setEventInfo] = useState({});
  const [showEvent, setShowEvent] = useState(false);

  useEffect(() => {
    setParentHeight(height - 80);
  }, [height]);

  useEffect(() => {
    dispatch(fetchStaffInfo());
    dispatch(fetchMeetingTypes());
    dispatch(fetchContactMethods());
    dispatch(fetchMeetingOutcomes());
    dispatch(fetchContactMethods());
  }, [dispatch]);

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateEvents({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    const types = [];
    types.push({ value: 0, label: 'All' });
    meetingTypes.forEach((mt) => types.push(mt));
    setAvailableMeetingTypes(types);
  }, [meetingTypes]);

  useEffect(() => {
    if (events) {
      setFilteredEvents(events);
    }
  }, [events]);

  const eventTypes = [
    {
      value: 0,
      label: t('all'),
    },
    {
      value: 1,
      label: t('meeting'),
    },
    {
      value: 2,
      label: t('communication'),
    },
    {
      value: 3,
      label: t('reminder'),
    },
  ];

  const groupEventsByMonth = (items) => items.reduce((acc, item) => {
    const groupName = dayjs(item.start_date).startOf('month').format('YYYY-MM-DD');
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(item);
    return acc;
  }, {});

  const handleEventType = (e) => {
    setSelectedEventType(e.value);
    if (e.value === 0) {
      setFilteredEvents(events);
    } else {
      setFilteredEvents(events.filter((i) => i.event_type === e.value));
    }
  };

  const handleMeetingType = (e) => {
    setSelectedMeetingType(e.value);
    if (e.value === 0) {
      setFilteredEvents(events.filter((i) => i.event_type === 1));
    } else {
      setFilteredEvents(events.filter((i) => i.event_type === 1)
        .filter((i) => i.metadata.meeting_type_id === e.value));
    }
  };

  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleNewEvent = (e, eventType) => {
    e.preventDefault();

    if (meetingTypes) {
      let metadata = {};
      if (eventType === 1) {
        metadata = {
          meeting_type_id: meetingTypes[0].id,
        };
      }

      const firstName = profile.first_name ? profile.first_name.trim() : '';
      const middleName = profile.middle_name ? profile.middle_name.trim() : '';
      const lastName = profile.last_name ? profile.last_name.trim() : '';

      handleCloseMenu();
      setEventInfo({
        id: undefined,
        event_type: eventType,
        subject: '',
        description: '',
        start_date: dayjs().format('YYYY-MM-DD HH:mm'),
        end_date: dayjs().add(30, 'minute').format('YYYY-MM-DD HH:mm'),
        all_day: eventType === 3,
        metadata,
        attendees: [
          {
            uuid: profile.uuid,
            type: 'candidate',
            name: [firstName, middleName, lastName]
              .filter((s) => s.trim() !== '').join(' '),
          },
          {
            uuid: currentUser.uuid,
            type: 'user',
            name: [currentUser.first_name, currentUser.last_name].filter((s) => s.trim() !== '').join(' '),
          },
        ],
      });
      setShowEvent(true);
    }
  };

  const handleOpenEventInfo = (id) => {
    const fe = events.find((e) => e.id === id);
    if (fe) {
      setEventInfo(fe);
      setShowEvent(true);
    }
  };

  const handleCloseEventInfo = () => {
    setShowEvent(false);
  };

  const setValue = (key, value) => {
    setEventInfo({
      ...eventInfo,
      [key]: value,
    });
  };

  const setMetadataValue = (key, value) => {
    setEventInfo({
      ...eventInfo,
      metadata: {
        ...eventInfo.metadata,
        [key]: value,
      },
    });
  };

  const handleDate = (e) => {
    setEventInfo({
      ...eventInfo,
      start_date: e[0] !== null ? e[0] : null,
      end_date: e[1] !== null ? e[1] : null,
    });
  };

  const handleSaveEvent = () => {
    if (eventInfo.subject !== null && eventInfo.subject !== undefined && eventInfo.subject !== ''
      && eventInfo.start_date !== null && eventInfo.start_date !== undefined && eventInfo.start_date !== ''
      && eventInfo.end_date !== null && eventInfo.end_date !== undefined && eventInfo.end_date !== '') {
      setOpenBackdrop(true);
      const data = {
        candidateId: uuid,
        eventType: eventInfo.event_type,
        startDate: eventInfo.start_date,
        endDate: eventInfo.end_date,
        subject: eventInfo.subject,
        description: eventInfo.description,
        metadata: JSON.stringify(eventInfo.metadata),
        eventAttendees: eventInfo.attendees
          // eslint-disable-next-line no-underscore-dangle
          .filter((u) => u.type === 'user').map((u) => ({ id: u.id, user_id: u.uuid, _destroy: u._destroy })),
        eventCandidateAttendees: eventInfo.attendees
          .filter((u) => u.type === 'candidate').map((u) => ({ id: u.id, candidate_id: u.uuid })),
      };

      if (eventInfo.id !== undefined) {
        data.id = eventInfo.id;
        dispatch(updateCandidateEvent(data));
      } else {
        dispatch(createCandidateEvent(data));
      }
    }
  };

  const handleDeleteEvent = (id) => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: t('are-you-sure', { keyPrefix: 'common' }),
        text: t('are-you-sure-you-want-to-delete-it', { keyPrefix: 'common' }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('yes-delete-it', { keyPrefix: 'common' }),
      })
      .then((result) => {
        if (result.isConfirmed) {
          setOpenBackdrop(true);
          dispatch(deleteCandidateEvent({
            eventId: id,
            candidateId: uuid,
          }));
        }
      });
  };

  if (created) {
    setOpenBackdrop(false);
    dispatch(setCreated(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('event-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      handleCloseEventInfo();
    });
  }
  if (saved) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('event-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      handleCloseEventInfo();
    });
  }

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      mt={3}
    >
      {!showEvent && (
        <>
          <Grid item md={3}>
            <FormSelect
              label={t('event-type')}
              options={eventTypes}
              value={findSelectValue(eventTypes, selectedEventType)}
              onChange={handleEventType}
            />
          </Grid>
          <Grid item md={3}>
            {selectedEventType === 1 && (
              <FormSelect
                label={t('meeting-type')}
                options={availableMeetingTypes}
                value={findSelectValue(availableMeetingTypes, selectedMeetingType)}
                onChange={handleMeetingType}
              />
            )}
          </Grid>
          <Grid item md={6}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <SoftButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleOpenMenu}
                >
                  <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                  &nbsp;
                  {t('add-new')}
                </SoftButton>
                <Menu
                  id="new-event-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={(e) => handleNewEvent(e, 1)}>{t('new-meeting')}</MenuItem>
                  <MenuItem onClick={(e) => handleNewEvent(e, 2)}>{t('new-communication')}</MenuItem>
                  <MenuItem onClick={(e) => handleNewEvent(e, 3)}>{t('new-reminder')}</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <div
              style={{
                minHeight: '300',
                height: parentHeight,
                overflowY: 'scroll',
              }}
            >
              {Object.entries(groupEventsByMonth(filteredEvents))
                .map(([date, items]) => (
                  <div key={`d-${date}`}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      mb={2}
                    >
                      <Grid item md={12}>
                        <SoftTypography variant="h4">
                          {dayjs(date).format('MMMM YY')}
                        </SoftTypography>
                      </Grid>
                      {items.map((item) => (
                        <Grid item md={12} m={1}>
                          <EventInfoCard event={item} openFunc={handleOpenEventInfo} />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
            </div>
          </Grid>
        </>
      )}
      {showEvent && (
        <>
          <Grid item md={12}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Button
                variant="text"
                size="small"
                startIcon={<ArrowBackIcon />}
                onClick={handleCloseEventInfo}
              >
                {t('back', { keyPrefix: 'common' })}
              </Button>
            </Stack>
            <Divider />
          </Grid>
          <Grid item md={12}>
            {eventInfo.event_type === 1 && (
              <MeetingEvent
                eventInfo={eventInfo}
                meetingTypes={meetingTypes}
                meetingOutcomes={meetingOutcomes}
                users={staff}
                setEventDate={handleDate}
                setValue={setValue}
                setMetadataValue={setMetadataValue}
              />
            )}
            {eventInfo.event_type === 2 && (
              <CommunicationEvent
                eventInfo={eventInfo}
                contactMethods={contactMethods}
                users={staff}
                setEventDate={handleDate}
                setValue={setValue}
                setMetadataValue={setMetadataValue}
              />
            )}
            {eventInfo.event_type === 3 && (
              <ReminderEvent
                eventInfo={eventInfo}
                users={staff}
                setEventDate={handleDate}
                setValue={setValue}
                setMetadataValue={setMetadataValue}
              />
            )}
          </Grid>
          <Grid item md={12}>
            <Grid
              container
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Grid item>
                {(eventInfo.id !== undefined) && (
                  <SoftButton
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteEvent(eventInfo.id)}
                  >
                    {t('delete', { keyPrefix: 'common' })}
                  </SoftButton>
                )}
              </Grid>
              <Grid item>
                <SoftButton variant="outlined" color="info" onClick={handleCloseEventInfo}>
                  {t('close', { keyPrefix: 'common' })}
                </SoftButton>
              </Grid>
              <Grid item>
                <SoftButton
                  variant="contained"
                  color="info"
                  onClick={handleSaveEvent}
                >
                  {t('save', { keyPrefix: 'common' })}
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Events;
