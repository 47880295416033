import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @mui material components
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// DevExtreme components
import DataGrid, {
  Editing,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Functions
import { renderColumns } from 'Util';
import { useEffect, useState } from 'react';

const selector = (state) => ({
  orderInfo: state.order.orderInfo,
});

function InvoiceSchedule() {
  const { t } = useTranslation('translation', { keyPrefix: 'orders' });
  const { orderInfo } = useSelector(selector, shallowEqual);
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState(false);

  useEffect(() => {
    if (orderInfo) {
      if (orderInfo.installment_plan_invoices) {
        setInvoices(orderInfo.installment_plan_invoices);
      }
    }
  }, [orderInfo]);

  const allowedPageSizes = [10, 30, 50, 'all'];

  const invoceState = (d) => {
    switch (d) {
      case 0:
        return t('draft', { keyPrefix: 'invoices' });
      case 1:
        return t('issued', { keyPrefix: 'invoices' });
      case 2:
        return t('overdue', { keyPrefix: 'invoices' });
      case 3:
        return t('paid', { keyPrefix: 'invoices' });
      case 4:
        return t('voided', { keyPrefix: 'invoices' });
      default:
        return d;
    }
  };

  const invoiceColumns = [
    {
      caption: t('billing-date'),
      field: 'date',
      dataType: 'date',
      sortOrder: 'asc',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('invoice-number'),
      field: 'invoice_number',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('due-date'),
      field: 'due',
      dataType: 'date',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('amount'),
      field: 'amount',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('status'),
      field: 'status',
      calculateCellValue: (d) => (invoceState(d.status)),
      visible: true,
      allowFixing: true,
    },
  ];

  const handleEditing = (e) => {
    navigate(`../billing/invoices/edit/${e.key}`);
  };

  return (
    <SoftBox>
      <Paper>
        <DataGrid
          id="invoiceSchedule"
          dataSource={invoices}
          keyExpr="uuid"
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onEditingStart={handleEditing}
        >
          <Editing
            mode="row"
            allowUpdating
            allowDeleting={false}
            allowAdding={false}
          />
          <HeaderFilter visible />
          <Scrolling rowRenderingMode="virtual" />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={30} />
          <Pager
            visible
            allowedPageSizes={allowedPageSizes}
            displayMode="full"
            showPageSizeSelector
            showInfo
            showNavigationButtons
          />
          <SearchPanel
            visible
            width={240}
            placeholder={t('search', { keyPrefix: 'common' })}
          />
          {renderColumns(invoiceColumns)}
        </DataGrid>
      </Paper>
    </SoftBox>
  );
}

export default InvoiceSchedule;
