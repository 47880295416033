import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from 'services/user.service';
import {
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchUsersInfo = createAsyncThunk(
  'user/fetchUsersInfo',
  async (payload, { rejectWithValue }) => {
    try {
      return await UserService.getUsers();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchStaffInfo = createAsyncThunk(
  'user/fetchStaffInfo',
  async (payload, { rejectWithValue }) => {
    try {
      return await UserService.getStaff();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAdvisorsInfo = createAsyncThunk(
  'user/fetchAdvisorsInfo',
  async (payload, { rejectWithValue }) => {
    try {
      return await UserService.getAdvisors();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchRecruitersInfo = createAsyncThunk(
  'user/fetchRecruitersInfo',
  async (payload, { rejectWithValue }) => {
    try {
      return await UserService.getRecruiters();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCoachesInfo = createAsyncThunk(
  'user/fetchCoachesInfo',
  async (payload, { rejectWithValue }) => {
    try {
      return await UserService.getCoaches();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchUserProfile = createAsyncThunk(
  'user/fetchUserProfile',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await UserService.getUserProfile(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      email,
      firstName,
      lastName,
      roleId,
      password,
      isActive,
    } = payload;
    try {
      return await UserService.updateUserProfile(
        uuid,
        email,
        firstName,
        lastName,
        roleId,
        password,
        isActive,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createUserProfile = createAsyncThunk(
  'user/createUserProfile',
  async (payload, { rejectWithValue }) => {
    const {
      email,
      firstName,
      lastName,
      roleId,
      password,
      isActive,
    } = payload;
    try {
      return await UserService.createUserProfile(
        email,
        firstName,
        lastName,
        roleId,
        password,
        isActive,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createUser = createAsyncThunk(
  'user/createUser',
  async (payload, { rejectWithValue }) => {
    const {
      email,
      firstName,
      lastName,
      roleId,
      password,
    } = payload;
    try {
      return await UserService.createUser(
        email,
        firstName,
        lastName,
        roleId,
        password,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteUserProfile = createAsyncThunk(
  'user/deleteUserProfile',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await UserService.deleteUserProfile(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateUserPassword = createAsyncThunk(
  'user/updateUserPassword',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      currentPassword,
      newPassword,
    } = payload;

    try {
      return await UserService.updateUserPassword(
        uuid,
        currentPassword,
        newPassword,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignCandidates = createAsyncThunk(
  'user/assignCandidates',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      candidates,
    } = payload;
    try {
      return await UserService.assignCandidates(uuid, candidates);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidates = createAsyncThunk(
  'user/updateCandidates',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      candidates,
    } = payload;
    try {
      return await UserService.updateCandidates(uuid, candidates);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const removeCandidates = createAsyncThunk(
  'user/removeCandidates',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      candidates,
    } = payload;
    try {
      return await UserService.removeCandidates(uuid, candidates);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const unlockCandidate = createAsyncThunk(
  'user/unlockCandidate',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
    } = payload;
    try {
      return await UserService.unlockCandidate(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchUserAvailabilities = createAsyncThunk(
  'user/fetchUserAvailabilities',
  async (payload, { rejectWithValue }) => {
    try {
      return await UserService.getUserAvailabilities();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchUserPermissions = createAsyncThunk(
  'user/fetchUserPermissions',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await UserService.getUserPermissions(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchUserActivity = createAsyncThunk(
  'user/fetchUserActivity',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      page,
    } = payload;
    try {
      return await UserService.fetchUserActivity(uuid, page);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchUserNotifications = createAsyncThunk(
  'user/fetchUserNotifications',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await UserService.fetchUserNotifications(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateNotification = createAsyncThunk(
  'user/updateNotification',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      id,
      readAt,
    } = payload;
    try {
      return await UserService.updateNotification(
        uuid,
        id,
        readAt,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.uuid,
  label: `${item.first_name} ${item.last_name}`,
}));

/* eslint-disable no-param-reassign */
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    staff: [],
    advisors: [],
    recruiters: [],
    coaches: [],
    userProfile: {},
    newPassword: {},
    isAdmin: false,
    editing: false,
    succeeded: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    message: '',
    availabilities: [],
    permissions: [],
    activity: { data: [], meta: { page: 0, pages: 0, total: 0 } },
    notifications: [],
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setNewPassword: (state, action) => {
      state.newPassword = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setUserEditing: (state, action) => {
      state.editing = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'user') {
          switch (performedAction[1]) {
            case 'fetchUsersInfo':
              fulfilledReducer(state, action);
              state.users = generateDropboxProperties(action);
              break;
            case 'fetchStaffInfo':
              fulfilledReducer(state, action);
              state.staff = generateDropboxProperties(action);
              break;
            case 'fetchUserProfile':
              fulfilledReducer(state, action);
              state.userProfile = action.payload;
              break;
            case 'updateUserProfile':
              fulfilledSavedReducer(state, action);
              break;
            case 'createUserProfile':
            case 'createUser':
              fulfilledReducer(state, action);
              state.created = true;
              break;
            case 'deleteUserProfile':
              fulfilledReducer(state, action);
              state.deleted = true;
              break;
            case 'assignCandidates':
            case 'updateCandidates':
            case 'removeCandidates':
            case 'unlockCandidate':
              fulfilledReducer(state, action);
              state.userProfile = action.payload.data;
              break;
            case 'updateUserPassword':
              fulfilledSavedReducer(state, action);
              state.userProfile = action.payload.data;
              break;
            case 'fetchUserPermissions':
              fulfilledReducer(state, action);
              state.permissions = action.payload;
              break;
            case 'fetchAdvisorsInfo':
              fulfilledReducer(state, action);
              state.advisors = generateDropboxProperties(action);
              break;
            case 'fetchRecruitersInfo':
              fulfilledReducer(state, action);
              state.recruiters = generateDropboxProperties(action);
              break;
            case 'fetchCoachesInfo':
              fulfilledReducer(state, action);
              state.coaches = generateDropboxProperties(action);
              break;
            case 'fetchUserActivity':
              fulfilledReducer(state, action);
              state.activity = action.payload;
              break;
            case 'fetchUserNotifications':
              fulfilledReducer(state, action);
              state.notifications = action.payload;
              break;
            case 'updateNotification':
              fulfilledReducer(state, action);
              state.notifications = action.payload.data;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  setUserProfile,
  setNewPassword,
  setUserEditing,
  setSucceeded,
  setCreated,
  setSaved,
  setDeleted,
  setFailed,
} = userSlice.actions;

export default userSlice.reducer;
