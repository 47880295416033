import {
  createCandidateCoaching,
  setErrors,
  setFailed,
  updateCandidateCoaching,
} from 'reducers/candidatesSlice';
import { modifiedRecords } from 'Util';

// eslint-disable-next-line import/prefer-default-export
export const saveCoaching = (
  t,
  dispatch,
  coaching,
  candidateId,
) => {
  if (coaching.changed !== undefined) {
    if (coaching.changed !== false) {
      const data = {
        candidateId,
        status: coaching.status,
        coachingSessions: modifiedRecords(coaching.candidate_coaching_sessions),
        coachingNashInstitutes: modifiedRecords(coaching.candidate_coaching_nash_institutes),
      };

      if (coaching.changed) {
        if (typeof coaching.id !== 'undefined') {
          data.id = coaching.id;
          dispatch(updateCandidateCoaching(data));
        } else {
          dispatch(createCandidateCoaching(data));
        }
      }
    } else {
      dispatch(setErrors(t('no-changes-to-save', { keyPrefix: 'common' })));
      dispatch(setFailed(true));
    }
  } else {
    dispatch(setErrors(t('entry-is-required-or-has-an-invalid-value', { keyPrefix: 'common' })));
    dispatch(setFailed(true));
  }
};
