import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchOrderTemplates } from 'reducers/orderTemplatesSlice';
import {
  fetchAccountBalance, fetchAccountExpenses,
  fetchAccountInvoices,
  fetchAccountPayments,
  fetchAccountTransactions, fetchOrderedServices,
} from 'reducers/accountsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Functions
import { currencyFormatter, getCandidateUUID } from 'Util';

// Components
import FlatInfoCard from 'layouts/components/Cards/InfoCards/FlatInfoCard';
import Invoices from '../Invoices';
import Payments from '../Payments';
import Expenses from '../Expenses';
import TransactionsTable from '../TransactionsTable';
import SummaryChart from '../SummaryChart';
import Services from '../Services';

const selector = (state) => ({
  balance: state.account.balance,
  transactions: state.account.transactions,
  invoices: state.account.invoices,
  payments: state.account.payments,
  expenses: state.account.expenses,
  orderedServices: state.account.orderedServices,
});

function AccountSummary() {
  const { t } = useTranslation('translation', { keyPrefix: 'accounts' });
  const {
    balance,
    transactions,
    invoices,
    payments,
    expenses,
    orderedServices,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [tabValue, setTabValue] = useState(0);
  const [calcTransactions, setCalcTransactions] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    dispatch(fetchOrderTemplates());

    if (uuid !== undefined) {
      dispatch(fetchAccountBalance({ id: uuid }));
      dispatch(fetchAccountTransactions({ id: uuid }));
      dispatch(fetchAccountInvoices({ id: uuid }));
      dispatch(fetchAccountPayments({ id: uuid }));
      dispatch(fetchAccountExpenses({ id: uuid }));
      dispatch(fetchOrderedServices({ id: uuid }));
    }
  }, [dispatch, uuid]);

  useEffect(() => {
    if (transactions) {
      const txs = transactions.map((tx) => ({ ...tx }))
        .sort((a, b) => new Date(a.doc_date) - new Date(b.doc_date));

      let cumulativeSum = 0;
      const updatedTxs = txs.map((tx) => {
        if (tx.doc_type === 'invoice') {
          cumulativeSum += parseFloat(tx.total);
        }
        if (tx.doc_type === 'payment') {
          cumulativeSum -= parseFloat(tx.total);
        }

        return { ...tx, amount: { value: tx.total, tx: tx.doc_type }, balance: cumulativeSum };
      });
      setCalcTransactions(updatedTxs);
    }
  }, [transactions]);

  useEffect(() => {
    if (orderedServices) {
      const srvs = [];
      orderedServices.forEach((o) => {
        o.order_items.forEach((i) => {
          srvs.push(i.service);
        });
      });
      setServices(srvs);
    }
  }, [orderedServices]);

  const handleChangeTab = (e, newValue) => {
    e.preventDefault();
    setTabValue(newValue);
  };

  return (
    <SoftBox mt={4}>
      <Grid container spacing={1} pb={1}>
        <Grid item md={6}>
          <Grid container spacing={1} pb={1}>
            <Grid item md={8}>
              <FlatInfoCard
                icon="account_circle"
                title={balance.payment_plans !== undefined
                  ? balance.payment_plans.join(', ') : t('none')}
                subtitle={t('payment-plan')}
              />
            </Grid>
            <Grid item md={4}>
              <FlatInfoCard
                icon="bubble_chart"
                title={balance.status === 'o' ? t('overdue') : t('good')}
                subtitle={t('account-status')}
              />
            </Grid>
            <Grid item md={6}>
              <FlatInfoCard
                icon="event_available"
                title={`${balance.last_payment_date !== null ? balance.last_payment_date : ''} 
            ${currencyFormatter.format(balance.last_payment_amount)}`}
                subtitle={t('last-payment')}
              />
            </Grid>
            <Grid item md={3}>
              <FlatInfoCard
                icon="account_balance"
                title={balance.balance !== undefined
                  ? `${currencyFormatter.format(balance.balance)}` : '$ 0'}
                subtitle={t('balance')}
              />
            </Grid>
            <Grid item md={3}>
              <FlatInfoCard
                icon="paid"
                title={currencyFormatter.format(balance.expenses)}
                subtitle={t('expenses')}
              />
            </Grid>
            <Grid item md={4}>
              <FlatInfoCard
                icon="payments"
                title={currencyFormatter.format(balance.payments)}
                subtitle={t('payments')}
              />
            </Grid>
            <Grid item md={4}>
              <FlatInfoCard
                icon="receipt_long"
                title={currencyFormatter.format(balance.invoices)}
                subtitle={t('billed')}
              />
            </Grid>
            <Grid item md={4}>
              <FlatInfoCard
                icon="sell"
                title={currencyFormatter.format(balance.total_invoices - balance.invoices)}
                subtitle={t('to-bill')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <SummaryChart transactions={transactions} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={7}>
          <TransactionsTable transactions={calcTransactions} />
        </Grid>
        <Grid item md={5}>
          <SoftBox sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} centered>
              <Tab label={t('services')} />
              <Tab label={t('invoices')} />
              <Tab label={t('payments')} />
              <Tab label={t('expenses')} />
            </Tabs>
          </SoftBox>
          {tabValue === 0 && <Services services={services} />}
          {tabValue === 1 && <Invoices invoices={invoices} />}
          {tabValue === 2 && <Payments payments={payments} />}
          {tabValue === 3 && <Expenses expenses={expenses} />}
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default AccountSummary;
