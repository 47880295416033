import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui core components
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

// Utils
import { defaultValue } from 'Util';

function SimplifyPearsonVueCards(
  {
    i,
    r,
    permissions = { dates: false, ncsbn: false },
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.nclex' });

  const [title, setTitle] = useState(`${t('pearson-vue')} ${i + 1}`);

  useEffect(() => {
    if (r.id !== undefined && r.id !== null) {
      setTitle(`${t('pearson-vue')} ${i + 1}`);
    }
  }, [i, r, t]);

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        title={title}
      />
      <CardContent>
        <Grid container spacing={1}>
          {permissions.dates && (
            <>
              <Grid item md={4}>
                <SoftTypography variant="body1" fontWeight="bold">{t('registration-date')}</SoftTypography>
              </Grid>
              <Grid item md={4}>
                {defaultValue(r.registration_date)}
              </Grid>
              <Grid item md={4} />
            </>
          )}
          {permissions.ncsbn && (
            <>
              <Grid item md={4}>
                <SoftTypography variant="body1" fontWeight="bold">{t('ncsbn-id')}</SoftTypography>
              </Grid>
              <Grid item md={4}>
                {defaultValue(r.ncsbn_id)}
              </Grid>
              <Grid item md={4} />
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

SimplifyPearsonVueCards.propTypes = {
  i: PropTypes.number.isRequired,
  r: PropTypes.shape(
    {
      id: PropTypes.number,
      user: PropTypes.string,
      password: PropTypes.string,
      registration_date: PropTypes.string,
      ncsbn_id: PropTypes.string,
      payment_date: PropTypes.string,
    },
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  permissions: PropTypes.shape({
    dates: PropTypes.bool,
    ncsbn: PropTypes.bool,
  }),
};

export default SimplifyPearsonVueCards;
