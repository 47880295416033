import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';
import FormSwitch from 'layouts/components/FormSwitch';

// Utils
import { findSelectValue } from 'Util';

function DefaultVisaProcessCard({
  i,
  v,
  ops,
  setValue,
  removeFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.immigration' });

  const [expanded, setExpanded] = useState(true);
  const [cardMenu, setCardMenu] = useState(null);

  const openCardMenu = (event) => setCardMenu(event.currentTarget);
  const closeCardMenu = () => setCardMenu(null);
  const handleExpand = () => {
    closeCardMenu();
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        action={(
          <IconButton onClick={openCardMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${t('process')} - ${i + 1}`}
      />
      <Menu
        anchorEl={cardMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(cardMenu)}
        onClose={closeCardMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={1.5}>
              <FormSelect
                label={t('visa-type')}
                options={ops.visaTypes}
                value={
                  findSelectValue(ops.visaTypes, v.visa_type_id)
                }
                onChange={(event) => {
                  setValue('visa_type_id', event.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormField
                label={t('starting-date')}
                type="date"
                value={v.i140_starting}
                onChange={(e) => {
                  setValue('i140_starting', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormField
                label={t('priority-date')}
                type="date"
                value={v.priority_date}
                onChange={(e) => {
                  setValue('priority_date', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              <FormField
                label={t('embassy-appointment')}
                type="date"
                value={v.embassy_appointment}
                onChange={(e) => {
                  setValue('embassy_appointment', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={3}>
              <FormSelect
                label={t('visa-status')}
                options={ops.visaStatus}
                value={
                  findSelectValue(ops.visaStatus, v.visa_status_id)
                }
                onChange={(e) => {
                  setValue('visa_status_id', e.value);
                }}
              />
            </Grid>
            <Grid item md={1.5}>
              <FormSwitch
                label={
                  v.active
                    ? t('active')
                    : t('inactive')
                }
                textTransform="none"
                checked={
                  v.active !== undefined
                    ? v.active
                    : false
                }
                onChange={(event) => {
                  setValue('active', event.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

DefaultVisaProcessCard.propTypes = {
  i: PropTypes.number.isRequired,
  v: PropTypes.shape(
    {
      id: PropTypes.number,
      visa_type_id: PropTypes.number,
      i140_starting: PropTypes.string,
      priority_date: PropTypes.string,
      embassy_appointment: PropTypes.string,
      visa_status_id: PropTypes.number,
      active: PropTypes.bool,
    },
  ).isRequired,
  ops: PropTypes.shape({
    visaTypes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    visaStatus: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultVisaProcessCard;
