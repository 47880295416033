import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { setNclexAssessmentTests } from 'reducers/candidatesSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// Components
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DefaultNclexAssessmentCard
  from 'layouts/components/Cards/NclexCards/DefaultNclexAssessmentCard';

// Functions
import { isValidValue } from 'Util';

const selector = (state) => ({
  nclexAssessmentTests: state.candidate.nclexAssessmentTests,
});

function ReadinessAssessments() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.coaching' });
  const {
    nclexAssessmentTests,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const handleAddNclexAssessment = () => {
    dispatch(
      setNclexAssessmentTests(
        nclexAssessmentTests.concat({
          id: undefined,
          date: '',
          score: '',
          result: '',
          questions: 0,
          challenging_topic_id: undefined,
          nclex_readiness_id: undefined,
          changed: true,
          valid: false,
        }),
      ),
    );
  };
  const isValidAssessment = (key, value, r) => {
    const objToValidate = {
      ...r,
      [key]: value,
    };

    return isValidValue(objToValidate.date)
      && isValidValue(objToValidate.result)
      && isValidValue(objToValidate.questions)
      && isValidValue(objToValidate.nclex_readiness_id);
  };
  const setAssessmentValue = (key, value, i) => {
    dispatch(
      setNclexAssessmentTests(
        nclexAssessmentTests.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValidAssessment(key, value, nclexAssessmentTests[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };
  const handleRemoveAssessment = (i) => {
    if (nclexAssessmentTests[i].id !== undefined) {
      setAssessmentValue('_destroy', true, i);
    } else {
      dispatch(
        setNclexAssessmentTests([
          ...nclexAssessmentTests.slice(0, i),
          ...nclexAssessmentTests.slice(i + 1),
        ]),
      );
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SoftTypography variant="h4" fontWeight="medium">
              {t('nclex-assessment-tests', { keyPrefix: 'candidates.nclex' })}
            </SoftTypography>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="body2" color="text">
                {t('nclex-assessment-tests-description', { keyPrefix: 'candidates.nclex' })}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item md={12} mb={3}>
            <SoftBox display="flex" justifyContent="flex-end">
              <SoftButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddNclexAssessment}
              >
                {t('new-nclex-assessment', { keyPrefix: 'candidates.nclex' })}
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SoftBox pb={1} px={1}>
              {Object.keys(nclexAssessmentTests).length > 0
                && nclexAssessmentTests
                  // eslint-disable-next-line no-underscore-dangle
                  .filter((r) => r._destroy !== true)
                  .map((n, i) => (
                    <div key={`nclex-assessment-test-${i}`}>
                      <DefaultNclexAssessmentCard
                        i={i}
                        c={n}
                        setValue={setAssessmentValue}
                        removeFunc={handleRemoveAssessment}
                      />
                      <Divider />
                    </div>
                  ))}
            </SoftBox>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ReadinessAssessments;
