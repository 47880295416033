import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Reducer functions
import {
  createCandidateNote,
  fetchCandidateNotes,
} from 'reducers/candidatesSlice';

// @material-ui core components
import Fab from '@mui/material/Fab';
import CommentIcon from '@mui/icons-material/Comment';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';
import SoftEditor from 'components/SoftEditor';

// Components
import NoteTimelineItem from 'layouts/components/Timeline/NoteTimelineItem';
import FormField from 'layouts/components/FormField';

// Util functions
import { getCandidateUUID } from 'Util';
import dayjs from 'dayjs';

dayjs.extend(require('dayjs/plugin/localizedFormat'));

const style = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 50,
  left: 'auto',
  position: 'fixed',
};

const selector = (state) => ({
  editing: state.candidate.editing,
  notes: state.candidate.notes,
  notesTimeline: state.candidate.notesTimeline,
});

function NotesWidget({ section }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.notes' });
  const {
    editing,
    notes,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [showNotes, setShowNotes] = useState(false);
  const [noteTitle, setNoteTitle] = useState('');
  const [noteContent, setNoteContent] = useState('');

  const handleOpenNotes = () => {
    setNoteTitle('');
    setNoteContent('');
    setShowNotes(true);
  };

  const handleCloseNotes = () => setShowNotes(false);

  const selectNoteStyle = (noteType) => {
    switch (noteType) {
      case 1:
        return {
          color: 'info',
          icon: 'sticky_note_2',
        };
      case 2:
        return {
          color: 'error',
          icon: 'connect_without_contact',
        };
      case 3:
        return {
          color: 'primary',
          icon: 'notifications',
        };
      default:
        return {
          color: 'info',
          icon: 'description',
        };
    }
  };

  const handleNoteTitle = (e) => setNoteTitle(e.target.value);
  const handleNoteContent = (e) => setNoteContent(e);

  const saveNotes = () => {
    if ((!noteTitle || noteTitle !== '') && (!noteContent || noteContent !== '')) {
      const currentDate = Date.parse(Date().toString()).toString('u').replace('Z', ' UTC');

      dispatch(
        createCandidateNote({
          candidateId: uuid,
          date: currentDate,
          title: noteTitle,
          note: noteContent,
          noteSectionId: section,
        }),
      );

      setShowNotes(false);
    }
  };

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateNotes({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  return (
    <div>
      <Fab
        size="small"
        color="secondary"
        aria-label="note"
        style={style}
        onClick={handleOpenNotes}
      >
        <CommentIcon />
      </Fab>
      <Dialog
        open={showNotes}
        onClose={handleCloseNotes}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{t('notes')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FormField
                label={t('title')}
                value={noteTitle !== undefined ? noteTitle : ''}
                onChange={handleNoteTitle}
                error={!noteTitle || noteTitle === ''}
              />
            </Grid>
            <Grid item md={12}>
              <SoftEditor
                value={noteContent}
                onChange={(newContent) => {
                  handleNoteContent(newContent);
                }}
              />
            </Grid>
            <Grid item md={12} mt={5}>
              <div
                style={{
                  maxHeight: 300,
                  overflow: 'auto',
                }}
              >
                {notes
                  && notes
                    .filter((n) => (n.note_section ? n.note_section.id === section : false))
                    .map((n, i) => (
                      <NoteTimelineItem
                        key={`note${i}`}
                        color={selectNoteStyle(n.note_type).color}
                        icon={selectNoteStyle(n.note_type).icon}
                        title={n.title}
                        dateTime={dayjs(n.date).format('LLL')}
                        description={n.note}
                        badges={[`${n.user.first_name} ${n.user.last_name}`]}
                        outcome={n.outcome}
                      />
                    ))}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SoftButton
            onClick={handleCloseNotes}
            variant="outlined"
            color="info"
          >
            {t('close', { keyPrefix: 'common' })}
          </SoftButton>
          <SoftButton
            onClick={saveNotes}
            color="info"
          >
            {t('save', { keyPrefix: 'common' })}
          </SoftButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Typechecking props for the NotesWidget
NotesWidget.propTypes = {
  section: PropTypes.number.isRequired,
};

export default NotesWidget;
