// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';

function FormField(
  {
    label = ' ',
    textTransform = 'capitalize',
    readOnly = false,
    ...rest
  },
) {
  return (

    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform={textTransform}
        >
          {label}
        </SoftTypography>
      </SoftBox>
      { }
      <SoftInput slotProps={{ input: { readOnly } }} {...rest} />
    </SoftBox>
  );
}

// Typechecking props for FormField
FormField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  textTransform: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  readOnly: PropTypes.bool,
};

export default FormField;
