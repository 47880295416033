function profileColumns(t) {
  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  return (
    [
      {
        caption: t('first-name', { keyPrefix: 'common' }),
        field: 'first_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('middle-name', { keyPrefix: 'common' }),
        field: 'middle_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('last-name', { keyPrefix: 'common' }),
        field: 'last_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('preferred-name', { keyPrefix: 'candidates.basic-info' }),
        field: 'preferred_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('birthdate', { keyPrefix: 'candidates.basic-info' }),
        field: 'dob',
        dataType: 'date',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('native-language', { keyPrefix: 'candidates.basic-info' }),
        field: 'language',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('country-of-origin', { keyPrefix: 'candidates.basic-info' }),
        field: 'country',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('country-of-residence', { keyPrefix: 'candidates.basic-info' }),
        field: 'residence_country',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('citizenship', { keyPrefix: 'candidates.basic-info' }),
        field: 'citizenship',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('preferred-contact-method', { keyPrefix: 'candidates.basic-info' }),
        field: 'contact_method',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('e-mail', { keyPrefix: 'candidates.contact-info' }),
        field: 'email',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('phone', { keyPrefix: 'candidates.contact-info' }),
        field: 'phone',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('address', { keyPrefix: 'candidates.contact-info' }),
        field: 'address',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('status'),
        field: 'candidate_status',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('advisor'),
        field: 'advisor_name',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('created-at'),
        field: 'created_at',
        calculateCellValue: (d) => handleDateFormat(d.created_at),
        visible: false,
        allowFixing: true,
        dataType: 'date',
      },
    ]
  );
}

export default profileColumns;
