import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchCandidateUpcomingEvents, setSaved } from 'reducers/candidatesSlice';
import { fetchAccountBalance } from 'reducers/accountsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Functions
import { getCandidateUUID, setTitle } from 'Util';
import Swal from 'sweetalert2';

// Widgets page components
import Events from 'layouts/candidates/components/Widgets/Events';
import AccountBalance from 'layouts/components/Widgets/AccountBalance';
import Recruiter from 'layouts/components/Widgets/Recruiter';
import PaymentPlans from 'layouts/components/Widgets/PaymentPlans';
import CandidateSummary from 'layouts/candidates/components/Widgets/CandidateSummary';
import LastPayment from 'layouts/components/Widgets/LastPayment';
import Advisor from 'layouts/components/Widgets/Advisor';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  upcoming: state.candidate.upcoming,
  balance: state.account.balance,
  saved: state.candidate.saved,
});

function Overview() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.overview' });
  const {
    currentUser,
    editing,
    upcoming,
    balance,
    saved,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateUpcomingEvents({ uuid }));
      if (currentUser.role.id >= 1 && currentUser.role.id <= 4) {
        dispatch(fetchAccountBalance({ id: uuid }));
      }
    }
  }, [currentUser, dispatch, editing, uuid]);

  useEffect(() => {
    if (upcoming !== undefined) {
      const es = [];
      upcoming.forEach((e) => {
        if (e.type === 'invoice_due') {
          es.push({
            title: t('invoice-due'),
            icon: 'notification_important',
            description: `${t('payment-due')} ${e.description.date}`,
          });
        }
        if (e.type === 'unsigned_agreement') {
          es.push({
            title: t('unsigned-agreement'),
            icon: 'notification_important',
            description: `${e.description.name} ${t('assigned-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'expiring_agreement') {
          es.push({
            title: t('expiring-agreement'),
            icon: 'report_problem',
            description: `${e.description.name} ${t('expiring-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'expired_agreement') {
          es.push({
            title: t('expired-agreement'),
            icon: 'error',
            description: `${e.description.name} ${t('expired-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'expiring_license') {
          es.push({
            title: t('expiring-license'),
            icon: 'report_problem',
            description: `${e.description.license} ${t('expiring-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'expired_license') {
          es.push({
            title: t('expired-license'),
            icon: 'error',
            description: `${e.description.license} ${t('expired-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'no_language_progress') {
          es.push({
            title: t('language-progress'),
            icon: 'info',
            description: `${t('no-language-progress-since')} ${e.description.date}`,
          });
        }
        if (e.type === 'credential_evaluation_expiring') {
          es.push({
            title: t('expiring-credential-evaluation'),
            icon: 'report_problem',
            description: `${t('credential-evaluation-expiring-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'credential_evaluation_expired') {
          es.push({
            title: t('expired-credential-evaluation'),
            icon: 'error',
            description: `${t('credential-evaluation-expired-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'bon_expiring') {
          es.push({
            title: t('expiring-bon'),
            icon: 'report_problem',
            description: `${t('bon-expiring-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'bon_expired') {
          es.push({
            title: t('expired-bon'),
            icon: 'error',
            description: `${t('bon-expired-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'att_expiring') {
          es.push({
            title: t('expiring-att'),
            icon: 'report_problem',
            description: `${t('att-expiring-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'att_expired') {
          es.push({
            title: t('expired-att'),
            icon: 'error',
            description: `${t('att-expired-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'archer_expiring') {
          es.push({
            title: t('expiring-archer'),
            icon: 'report_problem',
            description: `${t('archer-expiring-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'archer_expired') {
          es.push({
            title: t('expired-archer'),
            icon: 'error',
            description: `${t('archer-expired-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'saunders_expiring') {
          es.push({
            title: t('expiring-saunders'),
            icon: 'report_problem',
            description: `${t('saunders-expiring-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'saunders_expired') {
          es.push({
            title: t('expired-saunders'),
            icon: 'error',
            description: `${t('saunders-expired-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'passport_expiring') {
          es.push({
            title: t('expiring-passport'),
            icon: 'report_problem',
            description: `${t('passport-expiring-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'passport_expired') {
          es.push({
            title: t('expired-passport'),
            icon: 'error',
            description: `${t('passport-expired-on')} ${e.description.date}`,
          });
        }
        if (e.type === 'last_contact') {
          es.push({
            title: t('last-contact'),
            icon: 'info',
            description: `${t('no-contact-since')} ${e.description.date}`,
          });
        }
      });
      setEvents(es);
    }
  }, [t, upcoming]);

  setTitle('Candidate Overview');

  const setView = (value) => {
    if (value >= 1 && value <= 4) {
      return 'default';
    }
    if (value === 5) {
      return 'recruitment';
    }
    if (value === 10) {
      return 'coaching';
    }
    return 'default';
  };

  if (saved) {
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success')}!`,
      text: t('candidate-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close'),
    });
  }

  return (
    <SoftBox mb={3}>
      <Grid container spacing={2}>
        <Grid item md={3.5}>
          <CandidateSummary uuid={uuid} view={setView(currentUser.role.id)} />
        </Grid>
        <Grid item md={5}>
          {(currentUser.role.dashboard_id >= 1 && currentUser.role.dashboard_id <= 4) && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item md={12}>
                <PaymentPlans uuid={uuid} />
              </Grid>
              <Grid item md={6}>
                <AccountBalance uuid={uuid} />
              </Grid>
              <Grid item md={6}>
                <Recruiter uuid={uuid} />
              </Grid>
              <Grid item md={6}>
                {balance.last_payment_date !== null && (
                  <LastPayment uuid={uuid} />
                )}
              </Grid>
              <Grid item md={6} />
            </Grid>
          )}
          {(currentUser.role.dashboard_id === 5) && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item md={12}>
                <Advisor uuid={uuid} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item md={3.5}>
          <Events title={t('notifications')} events={events} />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Overview;
