import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @material-ui core components
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Collapse from '@mui/material/Collapse';

// Components
import FormField from 'layouts/components/FormField';
import FormSwitch from 'layouts/components/FormSwitch';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

function NashInstituteCard({
  i,
  ni = { id: null, enrollment_date: null, expiration_date: null },
  setValue,
  removeFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.coaching' });

  const [menu, setMenu] = useState(null);
  const [expanded, setExpanded] = useState(true);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleExpand = () => {
    closeMenu();
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${t('nash-institute')} - ${i + 1}`}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <FormField
                label={t('enrollment-date')}
                type="date"
                value={ni.enrollment_date ? ni.enrollment_date : ''}
                onChange={(e) => { setValue('enrollment_date', e.target.value, i); }}
              />
            </Grid>
            <Grid item md={4}>
              <FormField
                label={t('expiration-date')}
                type="date"
                value={ni.expiration_date ? ni.expiration_date : ''}
                onChange={(e) => { setValue('expiration_date', e.target.value, i); }}
              />
            </Grid>
            <Grid item md={4}>
              <FormSwitch
                label={
                  ni.active
                    ? t('active')
                    : t('inactive')
                }
                textTransform="none"
                checked={
                  ni.active !== undefined
                    ? ni.active
                    : false
                }
                onChange={(event) => {
                  setValue('active', event.target.checked, i);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

NashInstituteCard.propTypes = {
  i: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  ni: PropTypes.shape({
    id: PropTypes.number,
    enrollment_date: PropTypes.string,
    expiration_date: PropTypes.string,
    active: PropTypes.bool,
  }),
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default NashInstituteCard;
