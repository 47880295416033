import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer functions
import {
  clearPositionListing,
  createPositionListing,
  deletePositionListing,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  updatePositionListing,
} from 'reducers/positionListingsSlice';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';

// Functions
import Swal from 'sweetalert2';
import { errorsToString, MissingDataWarning, NoChangesWarning } from 'Util';

const selector = (state) => ({
  positionListingInfo: state.positionListing.positionListingInfo,
  editing: state.positionListing.editing,
  deleted: state.positionListing.deleted,
  created: state.positionListing.created,
  saved: state.positionListing.saved,
  updated: state.positionListing.updated,
  failed: state.positionListing.failed,
  errors: state.positionListing.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'position-listings' });

  const {
    positionListingInfo,
    editing,
    deleted,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created === true) {
    dispatch(setCreated(false));
    dispatch(clearPositionListing());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('position-listing-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/position-listings', { replace: true });
    });
  }

  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(clearPositionListing());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('position-listing-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/position-listings', { replace: true });
    });
  }

  if (deleted === true) {
    dispatch(setDeleted(false));
    dispatch(clearPositionListing({}));

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('position-listing-deleted-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/position-listings', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (positionListingInfo.changed === true) {
      if (positionListingInfo.name !== undefined && positionListingInfo.name !== ''
        && positionListingInfo.client_id !== undefined && positionListingInfo.client_id !== '') {
        const data = {
          name: positionListingInfo.name,
          description: positionListingInfo.description,
          clientId: positionListingInfo.client_id,
          openings: positionListingInfo.openings,
          dateOpened: positionListingInfo.date_opened,
          priorityId: positionListingInfo.priority_id,
          archived: positionListingInfo.archived,
        };

        if (editing === true) {
          data.id = id;

          dispatch(updatePositionListing(data));
        } else {
          dispatch(createPositionListing(data));
        }
      } else {
        MissingDataWarning(t);
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${positionListingInfo.name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(deletePositionListing(data));
      }
    });
  };

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              {t('delete', { keyPrefix: 'common' })}
            </SoftButton>
          </SoftBox>
        )}
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default SavePanel;
