import Service from './service';

class RoleService extends Service {
  async getRoles() {
    const response = await this.api
      .get(
        'roles',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getRole(id) {
    const response = await this.api
      .get(
        `roles/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createRole(
    name,
    allUsers,
    dashboardId,
    roleTypeId,
    rolePermissions,
    statuses,
    notes,
    meetingTypes,
  ) {
    const data = {
      role: {
        name,
        all_users: allUsers,
        dashboard_id: dashboardId,
        role_type_id: roleTypeId,
        role_permissions_attributes: rolePermissions,
        statuses,
        notes,
        meeting_types: meetingTypes,
      },
    };

    const response = await this.api
      .post(
        'roles',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateRole(
    id,
    name,
    allUsers,
    dashboardId,
    roleTypeId,
    rolePermissions,
    statuses,
    notes,
    meetingTypes,
  ) {
    const data = {
      role: {
        name,
        all_users: allUsers,
        dashboard_id: dashboardId,
        role_type_id: roleTypeId,
        role_permissions_attributes: rolePermissions,
        statuses,
        notes,
        meeting_types: meetingTypes,
      },
    };

    const response = await this.api
      .put(
        `roles/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteRole(id) {
    const response = await this.api
      .delete(
        `roles/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new RoleService();
