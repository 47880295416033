import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createCandidateMeeting,
  deleteCandidateMeeting,
  fetchCandidateMeetings,
  fetchCandidateProfile,
  setCreated,
  setSaved,
  updateCandidateMeeting,
} from 'reducers/candidatesSlice';
import {
  fetchMeetingOutcomes,
  fetchMeetingTypes,
} from 'reducers/commonSlice';
import { fetchUsersInfo } from 'reducers/usersSlice';

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import SoftEditor from 'components/SoftEditor';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormSwitch from 'layouts/components/FormSwitch';
import FormDateTimeRangePicker from 'layouts/components/FormDateTimeRangePicker';
import MeetingInfoCard from 'layouts/components/Cards/MeetingCard/MeetingInfoCard';
import MeetingDetailCard from 'layouts/components/Cards/MeetingCard/MeetingDetailCard';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';

// Functions
import {
  defaultValue, findSelectValue, getCandidateUUID, setTitle,
} from 'Util';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

const selector = (state) => ({
  editing: state.candidate.editing,
  created: state.candidate.created,
  saved: state.candidate.saved,
  currentUser: state.auth.user,
  profile: state.candidate.profile,
  meetings: state.candidate.meetings,
  contactOutcomes: state.common.contactOutcomes,
  meetingTypes: state.common.meetingTypes,
  meetingOutcomes: state.common.meetingOutcomes,
  users: state.user.users,
});

function Meetings() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.meetings' });
  const {
    editing,
    created,
    saved,
    currentUser,
    profile,
    meetings,
    meetingTypes,
    meetingOutcomes,
    users,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();
  const { height } = useWindowDimensions();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState(undefined);
  const [scheduleGroups, setScheduleGroups] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [showMeetingForm, setShowMeetingForm] = useState(false);
  const [availableMeetingTypes, setAvailableMeetingTypes] = useState([]);
  const [filterMeetingTypes, setFilterMeetingTypes] = useState(0);
  const [meeting, setMeeting] = useState({});
  const [parentHeight, setParentHeight] = useState(500);
  const [availableMeetingOutcomes, setAvailableMeetingOutcomes] = useState([]);

  const loadData = () => {
    dispatch(fetchMeetingTypes());
    dispatch(fetchUsersInfo());
    dispatch(fetchMeetingOutcomes());
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
      dispatch(fetchCandidateMeetings({ uuid }));
    }
  };
  const setSchedule = (meets) => meets.map((m) => ({
    id: m.id,
    subject: m.subject,
    group: dayjs(m.starts).format('YYYY MMMM'),
    startDate: new Date(m.starts),
    endDate: new Date(m.ends),
  }));
  const setGroups = (schedule) => {
    const groups = [];
    if (schedule.length > 0) {
      schedule.forEach((s) => {
        if (!groups.includes(s.group)) {
          groups.push(s.group);
        }
      });
    }
    return groups;
  };
  const setSchedulerData = () => {
    if (meetings !== null && meetings.length > 0) {
      setSelected(meetings[0]);
      const schedule = setSchedule(meetings);
      setCurrentSchedule(schedule);
      setScheduleGroups(setGroups(schedule));
    } else {
      setSelected(undefined);
    }
  };
  const getAttendees = (attendees) => (
    attendees
      .filter((a) => a.user_id !== undefined)
      .map((a) => (findSelectValue(users, a.user_id)
        ? findSelectValue(users, a.user_id).label : ''))
  );

  useEffect(loadData, [dispatch, editing, uuid]);
  useEffect(setSchedulerData, [meetings]);
  useEffect(() => {
    setParentHeight(height - 80);
  }, [height]);
  useEffect(() => {
    const types = [];
    types.push({ value: 0, label: 'All' });
    meetingTypes.forEach((mt) => types.push(mt));
    setAvailableMeetingTypes(types);
  }, [meetingTypes]);

  setTitle('Candidate Events');

  const yesNoStatus = [
    {
      value: true,
      label: t('yes', { keyPrefix: 'common' }),
    },
    {
      value: false,
      label: t('no', { keyPrefix: 'common' }),
    },
  ];

  const setValue = (key, value) => {
    setMeeting({
      ...meeting,
      [key]: value,
    });
  };
  const setMetadataValue = (key, value) => {
    const { metadata } = meeting;
    setMeeting({
      ...meeting,
      metadata: {
        ...metadata,
        [key]: value,
      },
    });
  };
  const handleDate = (e) => {
    setMeeting({
      ...meeting,
      starts: e[0] !== null ? e[0] : null,
      ends: e[1] !== null ? e[1] : null,
    });
  };
  const handleMeetingType = (e) => {
    setAvailableMeetingOutcomes(meetingOutcomes.filter((o) => o.meeting_type_id === e.value));
    if (meeting.subject !== undefined
      && meeting.subject !== null
      && meeting.subject !== '') {
      setValue('meeting_type_id', e.value);
    } else {
      const type = findSelectValue(meetingTypes, e.value);
      const found = meetings.filter((m) => m.meeting_type_id === type.id);

      const total = found.length + 1;
      setMeeting({
        ...meeting,
        subject: `${type.label} ${total.toString()}`,
        meeting_type_id: e.value,
      });
    }
  };
  const filterMeetingType = (e) => {
    let schedule = [];

    if (e.value === 0) {
      schedule = setSchedule(meetings);
    } else {
      schedule = setSchedule(meetings.filter((m) => m.meeting_type_id === e.value));
    }

    setCurrentSchedule(schedule);
    setScheduleGroups(setGroups(schedule));

    if (schedule.length > 0) {
      const found = meetings.filter((m) => m.id === schedule[0].id);
      setSelected(found[0]);
    } else {
      setSelected(undefined);
    }
    setFilterMeetingTypes(e.value);
  };
  const handleCloseMeetingForm = () => setShowMeetingForm(false);
  const handleSaveMeeting = () => {
    if (meeting.meeting_type_id !== null
      && meeting.meeting_type_id !== undefined
      && meeting.meeting_type_id !== ''
      && meeting.meeting_type_id !== 0
      && meeting.subject !== null
      && meeting.subject !== undefined
      && meeting.subject !== '') {
      setOpenBackdrop(true);
      const data = {
        candidateId: uuid,
        subject: meeting.subject,
        ends: meeting.ends,
        attended: meeting.attended,
        meetingTypeId: meeting.meeting_type_id,
        meetingOutcomeId: meeting.meeting_outcome_id,
        notes: meeting.notes,
        starts: meeting.starts,
        meetingAttendees: meeting.attendees,
        metadata: JSON.stringify(meeting.metadata),
      };

      if (meeting.id !== undefined) {
        data.id = meeting.id;
        dispatch(updateCandidateMeeting(data));
      } else {
        dispatch(createCandidateMeeting(data));
      }
    }
  };
  const handleDeleteMeeting = (id) => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: t('are-you-sure', { keyPrefix: 'common' }),
        text: t('are-you-sure-you-want-to-delete-it', { keyPrefix: 'common' }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('yes-delete-it', { keyPrefix: 'common' }),
      })
      .then((result) => {
        if (result.isConfirmed) {
          setOpenBackdrop(true);
          dispatch(deleteCandidateMeeting({
            id,
            candidateId: uuid,
          }));
        }
      });
  };
  const handleNewMeeting = () => {
    setMeeting({
      starts: dayjs(new Date(Math.round(dayjs() / (1000 * 60 * 5)) * (1000 * 60 * 5))),
      ends: dayjs(new Date(Math.round(dayjs() / (1000 * 60 * 5)) * (1000 * 60 * 5)))
        .add(1, 'hour').set('seconds', 0).set('millisecond', 0),
      attended: false,
      attendees: [
        { candidate_id: profile.uuid },
        { user_id: currentUser.uuid },
      ],
    });
    setShowMeetingForm(true);
  };
  const handleEditMeeting = (id) => {
    const meet = meetings.find((m) => m.id === id);
    setMeeting({
      id: meet.id,
      meeting_type_id: meet.meeting_type_id,
      meeting_outcome_id: meet.meeting_outcome_id,
      subject: meet.subject,
      starts: meet.starts,
      ends: meet.ends,
      attended: meet.attended,
      notes: meet.notes,
      attendees: meet.attendees,
    });
    setAvailableMeetingOutcomes(meetingOutcomes
      .filter((o) => o.meeting_type_id === meet.meeting_type_id));
    setShowMeetingForm(true);
  };

  const handleSelect = (e, id) => {
    e.preventDefault();
    const found = meetings.filter((m) => m.id === id);
    setSelected(found[0]);
  };
  const renderMeetsInfo = (schedule) => (
    schedule.map((m, i) => (
      <div key={`meet${i}`}>
        <MeetingInfoCard
          id={m.id}
          subject={m.subject}
          starts={m.startDate}
          ends={m.endDate}
          selected={selected !== undefined ? selected.id === m.id : false}
          clickFunc={(e) => handleSelect(e, m.id)}
          removeFunc={handleDeleteMeeting}
        />
      </div>
    ))
  );

  if (created) {
    setOpenBackdrop(false);
    dispatch(setCreated(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('meeting-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      handleCloseMeetingForm();
    });
  }
  if (saved) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('meeting-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      handleCloseMeetingForm();
    });
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      mt={3}
      mb={3}
    >
      <Grid item md={4}>
        <FormSelect
          label={t('meeting-type')}
          options={availableMeetingTypes}
          value={findSelectValue(availableMeetingTypes, filterMeetingTypes)}
          onChange={filterMeetingType}
        />
      </Grid>
      <Grid item md={5} />
      <Grid item md={3}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <SoftButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleNewMeeting}
            >
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              &nbsp;
              {t('add-new-meeting')}
            </SoftButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4}>
        <div
          style={{
            minHeight: '300',
            height: parentHeight,
            overflowY: 'scroll',
          }}
        >
          {scheduleGroups !== undefined && scheduleGroups.map((s) => (
            <div>
              <div style={{ margin: 7 }}>
                <SoftTypography variant="h4">{s}</SoftTypography>
              </div>
              {renderMeetsInfo(currentSchedule.filter((d) => d.group === s))}
            </div>
          ))}
        </div>
      </Grid>
      <Grid item md={8}>
        {selected !== undefined && (
          <MeetingDetailCard
            id={selected.id}
            subject={selected.subject}
            starts={selected.starts.toString()}
            ends={selected.ends.toString()}
            notes={selected.notes}
            type={findSelectValue(meetingTypes, selected.meeting_type_id) !== undefined
              ? findSelectValue(meetingTypes, selected.meeting_type_id).name : ''}
            attendees={getAttendees(selected.attendees)}
            editFunc={handleEditMeeting}
            removeFunc={handleDeleteMeeting}
          />
        )}
      </Grid>
      <Grid item md={12}>
        <Dialog
          open={showMeetingForm}
          onClose={handleCloseMeetingForm}
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle>{t('meeting')}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FormField
                  label={t('subject')}
                  value={defaultValue(meeting.subject)}
                  onChange={(e) => {
                    setValue('subject', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <FormDateTimeRangePicker
                  label={t('date')}
                  views={['day', 'hours']}
                  value={[dayjs(meeting.starts), dayjs(meeting.ends)]}
                  viewRenderers={{ hours: renderDigitalClockTimeView }}
                  onChange={handleDate}
                />
              </Grid>
              <Grid item md={3}>
                <FormSelect
                  label={t('attended')}
                  textTransform="none"
                  options={yesNoStatus}
                  value={findSelectValue(yesNoStatus, meeting.attended)}
                  onChange={(e) => {
                    setValue('attended', e.value);
                  }}
                />
              </Grid>
              <Grid item md={5}>
                <FormSelect
                  label={t('meeting-type')}
                  options={meetingTypes}
                  error={meeting.meeting_type_id ? false : !meeting.meeting_type_id || meeting.meeting_type_id === ''}
                  value={findSelectValue(meetingTypes, meeting.meeting_type_id)}
                  onChange={handleMeetingType}
                />
              </Grid>
              <Grid item md={6}>
                {availableMeetingOutcomes.length > 0 && (
                  <FormSelect
                    label={t('meeting-outcome')}
                    options={availableMeetingOutcomes}
                    error={meeting.meeting_outcome_id ? false : !meeting.meeting_outcome_id || meeting.meeting_outcome_id === ''}
                    value={findSelectValue(availableMeetingOutcomes, meeting.meeting_outcome_id)}
                    onChange={(e) => {
                      setValue('meeting_outcome_id', e.value);
                    }}
                  />
                )}
              </Grid>
              <Grid item md={12} mb={5} sx={{ height: '250px' }}>
                <SoftEditor
                  value={meeting.notes}
                  onChange={(newContent) => {
                    setValue('notes', newContent);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                {(meeting.meeting_type_id === 22) && (
                  <FormSwitch
                    label={`${t('deferred-payment-requested')}: ${meeting.metadata.need_deferral ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
                    textTransform="none"
                    checked={
                      meeting.metadata.need_deferral !== undefined
                        ? meeting.metadata.need_deferral
                        : false
                    }
                    onChange={(event) => {
                      setMetadataValue('need_deferral', event.target.checked);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={3}>
              <Grid item md={10}>
                {(meeting.id !== undefined) && (
                  <SoftButton
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteMeeting(meeting.id)}
                  >
                    {t('delete', { keyPrefix: 'common' })}
                  </SoftButton>
                )}
              </Grid>
              <Grid item md={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <SoftButton variant="outlined" color="info" onClick={handleCloseMeetingForm}>
                      {t('close', { keyPrefix: 'common' })}
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <SoftButton
                      variant="contained"
                      color="info"
                      onClick={handleSaveMeeting}
                    >
                      {t('save', { keyPrefix: 'common' })}
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Meetings;
