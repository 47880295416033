import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

// Components
import Carousel from 'react-material-ui-carousel';
import SimplifyNursingBoardCard
  from 'layouts/components/Cards/NursingBoardCard/SimplifyNursingBoardCard';
import SimplifyNclexCard from 'layouts/components/Cards/NclexCards/SimplifyNclexCard';
import SimplifyPearsonVueCards
  from 'layouts/components/Cards/PearsonVueCards/SimplifyPearsonVueCard';

const selector = (state) => ({
  countries: state.common.countries,
  states: state.common.states,
  nursingBoards: state.candidate.nursingBoards,
  nclex: state.candidate.nclex,
  nclexTests: state.candidate.nclexTests,
  pearsonRegistrations: state.candidate.pearsonRegistrations,
});

function NclexInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.coaching' });
  const {
    countries,
    states,
    nursingBoards,
    nclex,
    nclexTests,
    pearsonRegistrations,
  } = useSelector(selector, shallowEqual);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <SoftTypography variant="h4" fontWeight="medium">
              {t('educational-materials', { keyPrefix: 'candidates.nclex' })}
            </SoftTypography>
          </Grid>
          <Grid item md={6}>
            <Card sx={{ overflow: 'visible' }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={12} mt={1}>
                    <SoftTypography
                      variant="h5"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {t('saunders', { keyPrefix: 'candidates.nclex' })}
                    </SoftTypography>
                  </Grid>
                  <Grid item md={6}>
                    <SoftTypography variant="body1" fontWeight="bold">{t('date')}</SoftTypography>
                  </Grid>
                  <Grid item md={6}>
                    {nclex.saunders_registration_date}
                  </Grid>
                  <Grid item md={6}>
                    <SoftTypography variant="body1" fontWeight="bold">{t('expiration')}</SoftTypography>
                  </Grid>
                  <Grid item md={6}>
                    {nclex.saunders_expiration_date}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card sx={{ overflow: 'visible' }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={12} mt={1}>
                    <SoftTypography
                      variant="h5"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {t('archer', { keyPrefix: 'candidates.nclex' })}
                    </SoftTypography>
                  </Grid>
                  <Grid item md={6}>
                    <SoftTypography variant="body1" fontWeight="bold">{t('date')}</SoftTypography>
                  </Grid>
                  <Grid item md={6}>
                    {nclex.archer_registration_date}
                  </Grid>
                  <Grid item md={6}>
                    <SoftTypography variant="body1" fontWeight="bold">{t('expiration')}</SoftTypography>
                  </Grid>
                  <Grid item md={6}>
                    {nclex.archer_expiration_date}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <SoftTypography variant="h4" fontWeight="medium">
          {t('board-of-nursing')}
        </SoftTypography>
        <Carousel>
          {Object.keys(nursingBoards).length > 0
            && nursingBoards.map((b, i) => (
              <div key={`bon-${i}`}>
                <SimplifyNursingBoardCard e={b} i={i} ops={{ states }} />
              </div>
            ))}
        </Carousel>
      </Grid>
      <Grid item md={6}>
        <SoftTypography variant="h4" fontWeight="medium">
          {t('pearson-vue', { keyPrefix: 'candidates.nclex' })}
        </SoftTypography>
        <Carousel>
          {Object.keys(pearsonRegistrations).length > 0
            && pearsonRegistrations.map((r, i) => (
              <div key={`pearson-vue-${i}`}>
                <SimplifyPearsonVueCards
                  i={i}
                  r={r}
                  permissions={{
                    dates: true,
                    ncsbn: false,
                  }}
                />
              </div>
            ))}
        </Carousel>
      </Grid>
      <Grid item md={6}>
        <SoftTypography variant="h4" fontWeight="medium">
          {t('nclex')}
        </SoftTypography>
        <Carousel>
          {Object.keys(nclexTests).length > 0
            && nclexTests.map((n, i) => (
              <div key={`nclex-${i}`}>
                <SimplifyNclexCard c={n} i={i} ops={{ countries }} />
              </div>
            ))}
        </Carousel>
      </Grid>
    </Grid>
  );
}

export default NclexInfo;
