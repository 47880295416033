import Service from './service';

class PaymentService extends Service {
  async getPayments() {
    const response = await this.api
      .get(
        'payments',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getPayment(id) {
    const response = await this.api
      .get(
        `payments/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createPayment(
    date,
    provider,
    amount,
    reference,
    referenceId,
    metadata,
    candidateId,
    paymentMethodId,
    notes,
    invoices,
  ) {
    const data = {
      payment: {
        date,
        provider,
        amount,
        reference,
        reference_id: referenceId,
        metadata,
        candidate_id: candidateId,
        payment_method_id: paymentMethodId,
        notes,
        invoice_payments_attributes: invoices,
      },
    };

    const response = await this.api
      .post(
        'payments',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updatePayment(
    id,
    date,
    provider,
    amount,
    reference,
    referenceId,
    metadata,
    candidateId,
    paymentMethodId,
    notes,
    invoices,
  ) {
    const data = {
      payment: {
        date,
        provider,
        amount,
        reference,
        reference_id: referenceId,
        metadata,
        candidate_id: candidateId,
        payment_method_id: paymentMethodId,
        notes,
        invoice_payments_attributes: invoices,
      },
    };

    const response = await this.api
      .put(
        `payments/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async cancelPayment(id) {
    const response = await this.api
      .delete(
        `payments/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async refundPayment(id, notes) {
    const data = {
      payment: {
        id,
        notes,
      },
    };

    const response = await this.api
      .put(
        'payments/refund',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async getPaypalFee(total) {
    const data = {
      payment_collection: {
        total,
      },
    };

    const response = await this.api
      .post(
        'collections/paypal-fee',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async createPaypalPayment(id) {
    const data = {
      payment_collection: {
        id,
      },
    };

    const response = await this.api
      .post(
        'collections/paypal',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async createPaymentIntent(total, invoice, candidate) {
    const data = {
      payment_collection: {
        total,
        invoice,
        candidate,
      },
    };

    const response = await this.api
      .post(
        'collections/create-intent',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async createStripePayment(paymentIntent) {
    const data = {
      payment_collection: {
        payment_intent: paymentIntent,
      },
    };

    const response = await this.api
      .post(
        'collections/stripe',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new PaymentService();
