import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';
import Groups2Icon from '@mui/icons-material/Groups2';
import { Stack } from '@mui/system';
import { Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

// Components
import FormField from 'layouts/components/FormField';
import FormDateTimeRangePicker from 'layouts/components/FormDateTimeRangePicker';
import FormSelect from 'layouts/components/FormSelect';
import FormSwitch from 'layouts/components/FormSwitch';

// Soft UI Dashboard PRO React components
import SoftEditor from 'components/SoftEditor';

// Functions
import { defaultValue } from 'Util';
import dayjs from 'dayjs';

function ReminderEvent({
  eventInfo,
  users,
  setEventDate,
  setValue,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.events' });

  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);

  useEffect(() => {
    setAvailableUsers(
      users.filter(
        (u) => !eventInfo.attendees.filter((a) => a.type === 'user')
          .some((eu) => u.uuid === eu.uuid),
      ),
    );
  }, [eventInfo.attendees, users]);

  const handleAddUser = () => {
    if (selectedUsers) {
      const foundUser = users.find((user) => user.uuid === selectedUsers);
      if (foundUser) {
        const newUser = {
          id: undefined,
          uuid: foundUser.uuid,
          type: 'user',
          name: [foundUser.first_name, foundUser.last_name].filter((s) => s.trim() !== '').join(' '),
        };
        setSelectedUsers(null);
        const updatedAttendees = [...eventInfo.attendees, newUser];
        setValue('attendees', updatedAttendees);
      }
    }
  };

  const handleDeleteUser = (e, uuid) => {
    e.preventDefault();
    const candidate = eventInfo.attendees.find((a) => a.type === 'candidate');
    const attendees = eventInfo.attendees.filter((a) => a.type === 'user');

    const user = attendees.find((u) => u.uuid === uuid);
    const i = attendees.findIndex((u) => u.uuid === uuid);
    let updatedAttendees = [];

    if (user.id !== undefined) {
      updatedAttendees = [
        candidate,
        ...attendees.slice(0, i),
        ...attendees.slice(i + 1),
        { ...user, _destroy: true },
      ];
    } else {
      updatedAttendees = [
        candidate,
        ...attendees.slice(0, i),
        ...attendees.slice(i + 1),
      ];
    }
    setValue('attendees', updatedAttendees);
  };

  const renderCandidate = () => {
    const c = eventInfo.attendees.find((a) => a.type === 'candidate');
    if (c) {
      return (
        <Stack>
          <Chip label={c.name} color="primary" variant="outlined" />
        </Stack>
      );
    }
    return ('');
  };

  const renderUsers = () => {
    const us = eventInfo.attendees.filter((a) => a.type === 'user');
    if (us) {
      return (
        <Stack direction="row" spacing={1}>
          {us.map((u, i) => (
            <div
              key={`user-${i}`}
              style={{
                // eslint-disable-next-line no-underscore-dangle
                display: (u._destroy !== undefined ? 'none' : 'block'),
              }}
            >
              <Chip label={u.name} color="secondary" variant="outlined" onDelete={(e) => handleDeleteUser(e, u.uuid)} />
            </div>
          ))}
        </Stack>
      );
    }
    return ('');
  };

  return (
    <Grid
      container
      direction="row"
      spacing={1}
    >
      <Grid item md={12}>
        <FormField
          label={t('subject')}
          error={!eventInfo.subject || eventInfo.subject === ''}
          value={defaultValue(eventInfo.subject)}
          onChange={(e) => {
            setValue('subject', e.target.value);
          }}
        />
      </Grid>
      <Grid item md={4.5}>
        <FormDateTimeRangePicker
          label={t('date')}
          color="secondary"
          value={[dayjs(eventInfo.start_date), dayjs(eventInfo.end_date)]}
          viewRenderers={{ hours: renderDigitalClockTimeView }}
          onChange={setEventDate}
        />
      </Grid>
      <Grid item md={1.5} mt={4}>
        {`${t('all-day')}: ${eventInfo.all_day
          ? t('yes', { keyPrefix: 'common' })
          : t('no', { keyPrefix: 'common' })}`}
      </Grid>
      <Grid item md={1}>
        <FormSwitch
          textTransform="none"
          checked={
            eventInfo.all_day !== undefined
              ? eventInfo.all_day
              : false
          }
          onChange={(event) => {
            setValue('all_day', event.target.checked);
          }}
        />
      </Grid>
      <Grid item md={5} />
      <Grid item md={12} mb={5}>
        <SoftEditor
          value={eventInfo.description}
          onChange={(newContent) => {
            setValue('description', newContent);
          }}
        />
      </Grid>
      <Grid item md={3}>
        <FormSelect
          label={t('remind-to')}
          options={availableUsers}
          onChange={(e) => { setSelectedUsers(e.value); }}
        />
      </Grid>
      <Grid item md={3} mt={5}>
        <IconButton
          aria-label="add"
          onClick={handleAddUser}
        >
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item md={6} />
      <Grid item md={0.5}>
        <Groups2Icon />
      </Grid>
      <Grid item>
        {renderCandidate()}
      </Grid>
      <Grid item>
        {renderUsers()}
      </Grid>
    </Grid>
  );
}

ReminderEvent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  eventInfo: PropTypes.shape({
    id: PropTypes.number,
    subject: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    all_day: PropTypes.bool,
    description: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    metadata: PropTypes.any,
    attendees: PropTypes.arrayOf(
      PropTypes.shape(
        PropTypes.shape({
          uuid: PropTypes.string,
          type: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    ),
  }),
  // eslint-disable-next-line react/require-default-props
  users: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  // eslint-disable-next-line react/require-default-props
  setEventDate: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  setValue: PropTypes.func,
};

export default ReminderEvent;
