import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Reducer functions
import { setCurrentView, setGoToViewSubView } from 'reducers/candidatesSlice';
import { fetchRecruitmentCandidates } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

// DevExtreme components
import DataGrid, {
  ColumnChooser,
  ColumnFixing,
  Editing,
  Export,
  HeaderFilter,
  Item,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  Toolbar,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';

// Other components
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

// Functions
import { renderColumns, setTitle } from 'Util';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

const selector = (state) => ({
  recruitmentCandidates: state.reports.recruitmentCandidates,
});

function Candidates() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { recruitmentCandidates } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  setTitle('Recruitment Candidates Report');

  const [parentHeight, setParentHeight] = useState(500);
  const [fullScreen, setFullScreen] = useState(false);
  const [data, setData] = useState([]);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - (fullScreen ? 0 : 150));
  }, [fullScreen, height]);

  useEffect(() => {
    dispatch(fetchRecruitmentCandidates());
  }, [dispatch]);

  useEffect(() => {
    setData(recruitmentCandidates);
  }, [recruitmentCandidates]);

  const dataGrid = useRef(null);
  const allowedPageSizes = [10, 30, 50, 'all'];

  const commSkills = (d) => {
    switch (d) {
      case 1:
        return t('excellent', { keyPrefix: 'candidates.language-proficiency' });
      case 2:
        return t('good', { keyPrefix: 'candidates.language-proficiency' });
      case 3:
        return t('fair', { keyPrefix: 'candidates.language-proficiency' });
      default:
        return d;
    }
  };

  const columns = [
    {
      caption: t('candidate-name'),
      field: 'candidate_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('status', { keyPrefix: 'common' }),
      field: 'candidate_status_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('tn-eligibility', { keyPrefix: 'candidates.immigration' }),
      field: 'tn_eligibility',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-test', { keyPrefix: 'reports' }),
      field: 'english_test_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-test-date'),
      field: 'english_test_date',
      dataType: 'date',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('communication-skills', { keyPrefix: 'candidates.language-proficiency' }),
      field: 'communication_skills',
      calculateCellValue: (d) => (commSkills(d.communication_skills)),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('recruiter', { keyPrefix: 'candidates.recruiters' }),
      field: 'recruiter',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('r2r-expires-on'),
      field: 'r2r_expires_on',
      dataType: 'date',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('clinical-experiences'),
      field: 'clinical_experiences',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('education-level'),
      field: 'degree_type',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('hiring-hospital'),
      field: 'hospital_name',
      visible: true,
      allowFixing: true,
    },
  ];

  const handleEditing = (e) => {
    const viewSetup = {
      view: 'recruitment',
      subview: 'job-opportunities',
    };

    dispatch(setGoToViewSubView(viewSetup));
    dispatch(setCurrentView('recruitment'));
    navigate(`/candidates/edit/${e.key}`);
  };

  const handleExport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('RecruitmentCandidates');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'RecruitmentCandidates.xlsx');
      });
    });
  };

  const handleOpen = () => {
    setFullScreen(true);
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item md={12}>
        <Card style={{ width: '100%' }}>
          <Paper>
            <DataGrid
              id="dataview"
              ref={dataGrid}
              dataSource={data}
              keyExpr="uuid"
              allowColumnReordering
              allowColumnResizing
              columnAutoWidth
              onEditingStart={handleEditing}
              onExporting={handleExport}
              height={parentHeight}
            >
              <ColumnChooser enabled mode="select" />
              <ColumnFixing enabled />
              <Editing
                mode="row"
                allowUpdating
                allowDeleting={false}
                allowAdding={false}
              />
              <Export enabled />
              <HeaderFilter visible />
              <Scrolling rowRenderingMode="virtual" />
              <Sorting mode="multiple" />
              <Paging defaultPageSize={30} />
              <Pager
                visible
                allowedPageSizes={allowedPageSizes}
                displayMode="full"
                showPageSizeSelector
                showInfo
                showNavigationButtons
              />
              <SearchPanel
                visible
                width={240}
                placeholder={t('search', { keyPrefix: 'common' })}
              />
              <Toolbar>
                {!fullScreen && (
                  <Item location="before">
                    <IconButton aria-label="full-screen" onClick={handleOpen}>
                      <FullscreenIcon />
                    </IconButton>
                  </Item>
                )}
                <Item name="columnChooserButton" />
                <Item name="exportButton" />
                <Item name="searchPanel" />
              </Toolbar>
              {renderColumns(columns)}
            </DataGrid>
          </Paper>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Candidates;
