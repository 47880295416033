import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Reducer Functions
import { fetchInvoices } from 'reducers/invoicesSlice';
import {
  clearInvoicePayments, fetchInvoicePaymentByInvoice,
} from 'reducers/invoicePaymentsSlice';

// @mui material components
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// DevExpress components
import DataGrid, {
  Editing,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Functions
import { renderColumns } from 'Util';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import TabBar from 'layouts/components/TabBar';
import InvoicePaymentDetail from './components/InvoicePaymentDetail';

const selector = (state) => ({
  invoices: state.invoice.invoices,
});

function Invoices() {
  const { t } = useTranslation('translation', { keyPrefix: 'invoices' });
  const { invoices } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const invoicesGrid = useRef(null);

  useEffect(() => {
    dispatch(fetchInvoices());
    dispatch(clearInvoicePayments());
  }, [dispatch]);

  const allowedPageSizes = [10, 30, 50, 'all'];

  const [invoiceData, setInvoiceData] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState(false);

  useEffect(() => {
    setInvoiceData(invoices);
  }, [invoices]);

  const invoceState = (d) => {
    switch (d) {
      case 'draft':
        return t('draft');
      case 'issued':
        return t('issued');
      case 'overdue':
        return t('overdue');
      case 'paid':
        return t('paid');
      case 'voided':
        return t('voided');
      default:
        return d;
    }
  };

  const columns = [
    {
      caption: t('invoice-number'),
      field: 'invoice_number',
      width: 200,
    },
    {
      caption: t('candidate'),
      field: 'candidate_name',
    },
    {
      caption: t('date'),
      field: 'date',
      dataType: 'date',
      sortOrder: 'desc',
    },
    {
      caption: t('due'),
      field: 'due',
      dataType: 'date',
    },
    {
      caption: t('total'),
      field: 'total',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
    },
    {
      caption: t('balance'),
      field: 'balance',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
    },
    {
      caption: t('status'),
      field: 'status',
      calculateCellValue: (d) => (invoceState(d.status)),
    },
  ];

  const handleSetTabValue = (event, newValue) => {
    switch (newValue) {
      case 0:
        setInvoiceData(invoices);
        setPaidInvoices(false);
        break;
      case 1:
        setInvoiceData(invoices.filter((o) => o.status === 'draft'));
        setPaidInvoices(false);
        break;
      case 2:
        setInvoiceData(invoices.filter((o) => o.status === 'issued'));
        setPaidInvoices(false);
        break;
      case 3:
        setInvoiceData(invoices.filter((o) => o.status === 'overdue'));
        setPaidInvoices(false);
        break;
      case 4:
        setInvoiceData(invoices.filter((o) => o.status === 'paid'));
        setPaidInvoices(true);
        break;
      case 5:
        setInvoiceData(invoices.filter((o) => o.status === 'voided'));
        setPaidInvoices(false);
        break;
      default:
        setInvoiceData(invoices);
        setPaidInvoices(false);
        break;
    }
  };

  const handleNew = () => {
    navigate('new', { replace: true });
  };

  const handleEditing = (e) => {
    navigate(`/billing/invoices/edit/${e.key}`);
  };

  const handleStateReset = () => {
    invoicesGrid.current.instance.state(null);
  };

  const handleRowClick = (e) => {
    if (invoicesGrid.current.instance.isRowExpanded(e.data.uuid)) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.data.uuid);
      dispatch(clearInvoicePayments());
      dispatch(fetchInvoicePaymentByInvoice({ id: e.data.uuid }));
    }
  };

  return (
    <BaseLayout>
      <SoftBox display="flex" justifyContent="flex-end" mt={6} p={1}>
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleNew}
          >
            <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            &nbsp;
            {t('add-new-invoice')}
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <TabBar
        tabs={[t('all'), t('draft'), t('issued'), t('overdue'), t('paid'), t('voided')]}
        setTabValueFunction={handleSetTabValue}
      />
      <SoftBox display="flex" justifyContent="flex-end" p={1}>
        <SoftBox mr={1}>
          <SoftButton variant="text" color="info" onClick={handleStateReset}>
            {t('reset-filters', { keyPrefix: 'common' })}
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox>
        <Paper>
          <DataGrid
            id="invoices"
            dataSource={invoiceData}
            ref={invoicesGrid}
            keyExpr="uuid"
            onRowClick={handleRowClick}
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            onEditingStart={handleEditing}
          >
            <Editing
              mode="row"
              allowUpdating
              allowDeleting={false}
              allowAdding={false}
            />
            <HeaderFilter visible />
            <Scrolling rowRenderingMode="virtual" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={30} />
            <Pager
              visible
              allowedPageSizes={allowedPageSizes}
              displayMode="full"
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <SearchPanel
              visible
              width={240}
              placeholder={t('search', { keyPrefix: 'common' })}
            />
            <StateStoring
              enabled
              type="localStorage"
              storageKey="invoices-grid"
            />
            {renderColumns(columns)}
            {paidInvoices && <MasterDetail enabled component={InvoicePaymentDetail} />}
          </DataGrid>
        </Paper>
      </SoftBox>
    </BaseLayout>
  );
}

export default Invoices;
