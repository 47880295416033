// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// DevExtreme components

import { Chart, Series, Tooltip } from 'devextreme-react/chart';

// Other components
import PropTypes from 'prop-types';

function BarChar(
  {
    title = null,
    link = null,
    chartTitle = null,
    data,
    series,
    ...rest
  },
) {
  return (
    <Card>
      <Grid container sx={{ m: 2 }}>
        {(title !== null) && (
          <Grid item md={link !== null ? 8 : 12}>
            <SoftTypography variant="body2" fontFamily="sans-serif">{title}</SoftTypography>
          </Grid>
        )}
        {(link !== null) && (
          <Grid item md={title !== null ? 4 : 12}>
            <SoftTypography variant="subtitle2" fontFamily="sans-serif">{link}</SoftTypography>
          </Grid>
        )}
        <Grid item md={12}>
          <Chart
            id="chart"
            dataSource={data}
            title={chartTitle}
            {...rest}
          >
            {series && series.map((s, i) => (
              <Series
                key={`series${i}`}
                valueField={s.valueField}
                argumentField={s.argumentField}
                name={s.name}
                type="bar"
                color={s.color}
              />
            ))}
            <Tooltip enabled />
          </Chart>
        </Grid>
      </Grid>
    </Card>
  );
}

BarChar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  link: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  chartTitle: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  series: PropTypes.any.isRequired,
};

export default BarChar;
