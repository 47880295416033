import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducers
import { fetchOrder, setOrderEditing } from 'reducers/ordersSlice';
import { fetchServices } from 'reducers/servicesSlice';
import { fetchPaymentPlans } from 'reducers/paymentPlansSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import OrderInfo from '../components/OrderInfo';
import OrderDetails from '../components/OrderDetails';
import SavePanel from '../components/SavePanel';
import InvoiceSchedule from '../components/InvoiceSchedule';

function EditOrder() {
  const { t } = useTranslation('translation', { keyPrefix: 'orders' });
  const dispatch = useDispatch();

  const { id } = useParams();

  const [showInvoicing, setShowInvoicing] = useState(false);

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchPaymentPlans());
    dispatch(fetchOrder({ id }));
    dispatch(setOrderEditing(true));
  }, [dispatch, id]);

  const handleCloseInvoicing = () => setShowInvoicing(false);

  return (
    <BaseLayout>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <OrderInfo />
                </Grid>
                <Grid item md={12}>
                  <Grid
                    container
                    direction="row"
                    sx={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={() => setShowInvoicing(true)}
                    >
                      {t('show-invoicing')}
                    </SoftButton>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <OrderDetails />
                  <Divider />
                </Grid>
                <Grid item md={12}>
                  <SavePanel />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <Dialog
        open={showInvoicing}
        onClose={handleCloseInvoicing}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t('invoicing')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InvoiceSchedule />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <SoftButton variant="outlined" size="small" color="info" onClick={handleCloseInvoicing}>
                    {t('close', { keyPrefix: 'common' })}
                  </SoftButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </BaseLayout>
  );
}

export default EditOrder;
