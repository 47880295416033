import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  fetchCandidateCoaching,
  fetchCandidateNclexAssessmentTests,
  fetchCandidateNclexs,
  fetchCandidateNclexTests,
  fetchCandidateNursingBoards,
  fetchCandidatePearson,
  setCurrentSubView,
} from 'reducers/candidatesSlice';
import { fetchCountries, fetchStates } from 'reducers/commonSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Functions
import {
  checkPermission,
  getCandidateUUID,
  moveSubView,
  setTitle,
} from 'Util';

// Components
import TabBar from 'layouts/components/TabBar';
import SavePanel from '../SavePanel';
import NotesWidget from '../NotesWidget';
import General from './components/General';
import NclexInfo from './components/NclexInfo';
import ReadinessAssessments from './components/ReadinessAssessments';

const selector = (state) => ({
  currentUser: state.auth.user,
  currentSubView: state.candidate.currentSubView,
  goToSubView: state.candidate.goToSubView,
  editing: state.candidate.editing,
});

function Coaching() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.coaching' });
  const {
    currentUser,
    currentSubView,
    goToSubView,
    editing,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [defaultTab, setDefaultTab] = useState(0);

  setTitle('Candidate Coaching');

  useEffect(() => {
    if (checkPermission('CCGI', currentUser)) {
      dispatch(setCurrentSubView('coaching'));
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchStates({ id: 233 }));

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateCoaching({ uuid }));
      dispatch(fetchCandidateNursingBoards({ uuid }));
      dispatch(fetchCandidateNclexs({ uuid }));
      dispatch(fetchCandidateNclexTests({ uuid }));
      dispatch(fetchCandidateNclexAssessmentTests({ uuid }));
      dispatch(fetchCandidatePearson({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  const tabs = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const views = [];

  const handleSetTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('general'):
        dispatch(setCurrentSubView('general'));
        break;
      case t('nclex'):
        dispatch(setCurrentSubView('nclex-info'));
        break;
      case t('readiness-assessments'):
        dispatch(setCurrentSubView('nclex'));
        break;
      default:
        dispatch(setCurrentSubView('general'));
        break;
    }
  };

  if (checkPermission('CCGI', currentUser)) {
    tabs.push(t('general'));
    views.push('coaching');
  }

  if (checkPermission('CCNC', currentUser)) {
    tabs.push(t('nclex'));
    views.push('nclex-info');
  }

  if (checkPermission('CCRA', currentUser)) {
    tabs.push(t('readiness-assessments'));
    views.push('nclex');
  }

  useEffect(() => {
    moveSubView(views, goToSubView, setDefaultTab, dispatch, currentSubView);
  }, [dispatch, views, currentSubView, goToSubView]);

  return (
    <div id="coaching">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetTabValue}
        defaultTabValue={defaultTab}
      />
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item md={12} mt={3}>
            {currentSubView === 'coaching' && (
              <General />
            )}
            {currentSubView === 'nclex-info' && (
              <NclexInfo />
            )}
            {currentSubView === 'nclex' && (
              <ReadinessAssessments />
            )}
          </Grid>
        </Grid>
        <Grid item md={12}>
          <SavePanel />
        </Grid>
        {(checkPermission('CANT', currentUser)) && (
          <NotesWidget section={14} />
        )}
      </SoftBox>
    </div>
  );
}

export default Coaching;
