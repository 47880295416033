import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

function Services({ services = [] }) {
  const { t } = useTranslation('translation', { keyPrefix: 'accounts' });

  return (
    <Card>
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          {t('services')}
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <List dense>
          {Object.keys(services).length > 0
            && services.map((b) => (
              <ListItem>
                <ListItemIcon>
                  <LabelOutlinedIcon size="small" />
                </ListItemIcon>
                <ListItemText
                  primary={b}
                />
              </ListItem>
            ))}
        </List>
      </SoftBox>
    </Card>
  );
}

Services.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  services: PropTypes.array.isRequired,
};

export default Services;
