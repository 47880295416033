import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// Reducers functions
import { setRecruiterInfo } from 'reducers/recruiterSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftEditor from 'components/SoftEditor';

// Components
import FormField from 'layouts/components/FormField';
import TabBar from 'layouts/components/TabBar';
import Contacts from '../Contacts';

const selector = (state) => ({
  editing: state.recruiter.editing,
  recruiterInfo: state.recruiter.recruiterInfo,
});

function RecruiterInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'recruiters' });

  const { editing, recruiterInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [currentTabView, setCurrentTabView] = useState(t('recruiter-info'));

  const tabs = [];
  tabs.push(t('recruiter-info'));
  if (editing) { tabs.push(t('contacts')); }

  const setValue = (key, value) => {
    dispatch(
      setRecruiterInfo(
        {
          ...recruiterInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleSetTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('recruiter-info'):
        setCurrentTabView(t('recruiter-info'));
        break;
      case t('contacts'):
        setCurrentTabView(t('contacts'));
        break;
      default:
        setCurrentTabView(t('recruiter-info'));
        break;
    }
  };

  return (
    <div id="recruiter">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetTabValue}
        defaultTabValue={0}
      />
      {currentTabView === t('recruiter-info') && (
        <Card id="recruiter-info" sx={{ overflow: 'visible' }}>
          <SoftBox p={3}>
            <SoftTypography variant="h5">{t('recruiter-info')}</SoftTypography>
          </SoftBox>
          <SoftBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <FormField
                  label={t('name')}
                  value={recruiterInfo.name !== undefined ? recruiterInfo.name : ''}
                  error={!recruiterInfo.name || recruiterInfo.name === ''}
                  onChange={(e) => {
                    setValue('name', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <FormField
                  label={t('code')}
                  value={recruiterInfo.code !== undefined ? recruiterInfo.code : ''}
                  onChange={(e) => {
                    setValue('code', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t('description')}
                </SoftTypography>
                <SoftEditor
                  value={recruiterInfo.description}
                  onChange={(newContent) => {
                    setValue('description', newContent);
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      )}
      {(currentTabView === t('contacts') && editing) && (
        <Contacts />
      )}
    </div>
  );
}

export default RecruiterInfo;
