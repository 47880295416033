import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer functions
import {
  clearRecruiter,
  createRecruiter,
  deleteRecruiter,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  updateRecruiter,
} from 'reducers/recruiterSlice';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';

// Functions
import Swal from 'sweetalert2';
import { errorsToString, MissingDataWarning, NoChangesWarning } from 'Util';

const selector = (state) => ({
  recruiterInfo: state.recruiter.recruiterInfo,
  editing: state.recruiter.editing,
  deleted: state.recruiter.deleted,
  created: state.recruiter.created,
  saved: state.recruiter.saved,
  updated: state.recruiter.updated,
  failed: state.recruiter.failed,
  errors: state.recruiter.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'recruiters' });

  const {
    recruiterInfo,
    editing,
    deleted,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created === true) {
    dispatch(setCreated(false));
    dispatch(clearRecruiter());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('recruiter-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/recruiters', { replace: true });
    });
  }

  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(clearRecruiter());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('recruiter-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/recruiters', { replace: true });
    });
  }

  if (deleted === true) {
    dispatch(setDeleted(false));
    dispatch(clearRecruiter({}));

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('recruiter-deleted-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/recruiters', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (recruiterInfo.changed === true) {
      if (recruiterInfo.name !== undefined && recruiterInfo.name !== '') {
        const data = {
          name: recruiterInfo.name,
          description: recruiterInfo.description,
          code: recruiterInfo.code,
        };

        if (editing === true) {
          data.id = id;

          dispatch(updateRecruiter(data));
        } else {
          dispatch(createRecruiter(data));
        }
      } else {
        MissingDataWarning(t);
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${recruiterInfo.name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(deleteRecruiter(data));
      }
    });
  };

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              {t('delete', { keyPrefix: 'common' })}
            </SoftButton>
          </SoftBox>
        )}
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default SavePanel;
