function recruiterColumns(t) {
  const commSkills = (d) => {
    switch (d) {
      case 1:
        return t('excellent', { keyPrefix: 'candidates.language-proficiency' });
      case 2:
        return t('good', { keyPrefix: 'candidates.language-proficiency' });
      case 3:
        return t('fair', { keyPrefix: 'candidates.language-proficiency' });
      default:
        return d;
    }
  };

  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  return (
    [
      {
        caption: t('first-name', { keyPrefix: 'common' }),
        field: 'first_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('last-name', { keyPrefix: 'common' }),
        field: 'last_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('tn-eligibility', { keyPrefix: 'candidates.immigration' }),
        field: 'tn_eligibility',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('comm-skills', { keyPrefix: 'candidates.language-proficiency' }),
        field: 'communication_skills',
        calculateCellValue: (d) => (commSkills(d.communication_skills)),
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('recruiter', { keyPrefix: 'candidates.recruiters' }),
        field: 'recruiter',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('status', { keyPrefix: 'common' }),
        field: 'candidate_status_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('r2r-valid', { keyPrefix: 'candidates.agreements' }),
        field: 'r2r_valid',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('r2r-expiration', { keyPrefix: 'candidates.agreements' }),
        field: 'r2r_expires_on',
        calculateCellValue: (d) => handleDateFormat(d.r2r_expires_on),
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('country-of-origin', { keyPrefix: 'candidates.basic-info' }),
        field: 'country_name',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('e-mail', { keyPrefix: 'candidates.contact-info' }),
        field: 'default_email',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('phone', { keyPrefix: 'candidates.contact-info' }),
        field: 'default_phone',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('current-advisor', { keyPrefix: 'candidates.basic-info' }),
        field: 'current_advisor_name',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('country-of-residence', { keyPrefix: 'candidates.basic-info' }),
        field: 'residence_country_name',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('english-test', { keyPrefix: 'reports' }),
        field: 'english_test_name',
        visible: false,
        allowFixing: true,
      },
    ]);
}

export default recruiterColumns;
