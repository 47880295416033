import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions

import { fetchCandidateDegrees, setCurrentSubView, setEducation } from 'reducers/candidatesSlice';
// eslint-disable-next-line object-curly-newline
import { fetchCitiesIndex, fetchCountries, fetchEducationLevels, fetchStatesIndex } from 'reducers/commonSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Components
import EducationCard from 'layouts/components/Cards/EducationCards/DefaultEducationCard';
import Divider from '@mui/material/Divider';

// Function
import {
  checkPermission,
  getCandidateUUID,
  isValidValue,
  setTitle,
} from 'Util';
import NotesWidget from '../NotesWidget';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  education: state.candidate.education,
  removeData: state.candidate.removeData,
  educationLevels: state.common.educationLevels,
  countries: state.common.countries,
});

function EducationInfo() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.education',
  });
  const {
    currentUser,
    editing,
    education,
    educationLevels,
    countries,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('education'));
    dispatch(fetchCountries());
    dispatch(fetchEducationLevels());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateDegrees({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Education Info');

  const [firstLoad, setFirstLoad] = useState(true);

  const isValid = (key, value, o) => {
    const objToValidate = {
      ...o,
      [key]: value,
    };

    const isInstitutionsValid = objToValidate
      .education_institutions.find((x) => x.valid === false);

    return isValidValue(objToValidate.degree)
      && isValidValue(objToValidate.education_level_id)
      && isValidValue(objToValidate.graduation)
      && isInstitutionsValid === undefined;
  };

  const setValue = (key, value, i) => {
    dispatch(
      setEducation(
        education.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValid(key, value, education[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleAddDegree = () => {
    dispatch(
      setEducation(
        education.concat({
          id: undefined,
          degree: '',
          education_level_id: undefined,
          graduation: '',
          name_under: '',
          education_institutions: [],
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveDegree = (i) => {
    if (education[i].id !== undefined) {
      setValue('_destroy', true, i);
    } else {
      dispatch(setEducation([...education.slice(0, i), ...education.slice(i + 1)]));
    }
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);

      education.forEach((d, i) => {
        if (
          d.country_id !== undefined
          && d.country_id !== null
          && d.country_id !== ''
        ) {
          dispatch(
            fetchStatesIndex({
              id: d.country_id,
              index: i,
            }),
          ).then(() => {
            if (
              d.state_id !== undefined
              && d.state_id !== null
              && d.state_id !== ''
            ) {
              dispatch(
                fetchCitiesIndex({
                  countryId: d.country_id,
                  stateId: d.state_id,
                  index: i,
                }),
              );
            }
          });
        }
      });
    }
  }, [dispatch, firstLoad, education]);

  return (
    <SoftBox component="form">
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <SoftTypography variant="h4" fontWeight="medium">
              {t('education')}
            </SoftTypography>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="body2" color="text">
                {t('education-description')}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item md={12}>
            <SoftBox display="flex" justifyContent="flex-end">
              <SoftButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddDegree}
              >
                {t('new-degree')}
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            {Object.keys(education).length > 0

              && education.map((d, i) => (
                <div
                  key={`education-${i}`}
                  style={{
                    // eslint-disable-next-line no-underscore-dangle
                    display: (d._destroy !== undefined ? 'none' : 'block'),
                    paddingBottom: '10px',
                  }}
                >
                  <EducationCard
                    ops={{
                      countries,
                      educationLevels,
                    }}
                    d={d}
                    setValue={setValue}
                    i={i}
                    removeFunc={handleRemoveDegree}
                  />
                  <Divider />
                </div>
              ))}
          </Grid>
        </Grid>
      </SoftBox>
      {(checkPermission('CANT', currentUser)) && (
        <NotesWidget section={5} />
      )}
    </SoftBox>
  );
}

export default EducationInfo;
