import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducers functions
import { setClientInfo } from 'reducers/clientSlice';
import { fetchCities } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import TabBar from 'layouts/components/TabBar';
import SoftEditor from 'components/SoftEditor';

// Util functions
import { findSelectValue } from 'Util';
import Contacts from '../Contacts';
import LegalInfo from '../LegalInfo';
import Tags from '../Tags';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.client.editing,
  clientInfo: state.client.clientInfo,
  states: state.common.states,
  cities: state.common.cities,
});

function ClientInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'clients' });

  const {
    editing,
    clientInfo,
    states,
    cities,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [currentTabView, setCurrentTabView] = useState(t('client-info'));

  useEffect(() => {
    if (clientInfo !== undefined
      && clientInfo.state_id !== null
      && clientInfo.state_id !== undefined) {
      dispatch(
        fetchCities({
          countryId: 233,
          stateId: clientInfo.state_id,
        }),
      );
    }
  }, [clientInfo, dispatch]);

  const tabs = [];
  tabs.push(t('client-info'));
  if (editing) { tabs.push(t('contacts')); }
  tabs.push(t('legal-info'));
  tabs.push(t('notes'));
  tabs.push(t('tags'));

  const setValue = (key, value) => {
    dispatch(
      setClientInfo(
        {
          ...clientInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleSetTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('client-info'):
        setCurrentTabView(t('client-info'));
        break;
      case t('contacts'):
        setCurrentTabView(t('contacts'));
        break;
      case t('legal-info'):
        setCurrentTabView(t('legal-info'));
        break;
      case t('notes'):
        setCurrentTabView(t('notes'));
        break;
      case t('tags'):
        setCurrentTabView(t('tags'));
        break;
      default:
        setCurrentTabView(t('client-info'));
        break;
    }
  };

  const handleCountry = (e) => {
    if (e.id !== undefined) {
      dispatch(
        fetchCities({
          countryId: 233,
          stateId: e.id,
        }),
      );

      setValue('state_id', e.value);
    }
  };

  return (
    <div id="client">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetTabValue}
        defaultTabValue={0}
      />
      {currentTabView === t('client-info') && (
        <Card id="client-info" sx={{ overflow: 'visible' }}>
          <CardHeader
            title={t('client-info')}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FormField
                  label={t('name')}
                  value={clientInfo.name !== undefined ? clientInfo.name : ''}
                  error={!clientInfo.name || clientInfo.name === ''}
                  onChange={(e) => {
                    setValue('name', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={8}>
                <FormField
                  label={t('website')}
                  value={clientInfo.website !== undefined ? clientInfo.website : ''}
                  onChange={(e) => {
                    setValue('website', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <FormField
                  label={t('code')}
                  value={clientInfo.code !== undefined ? clientInfo.code : ''}
                  onChange={(e) => {
                    setValue('code', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FormField
                  label={t('address')}
                  value={clientInfo.address !== undefined ? clientInfo.address : ''}
                  onChange={(e) => {
                    setValue('address', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <FormSelect
                  label={t('state')}
                  placeholder={t('state')}
                  options={states}
                  value={
                    states !== undefined
                      ? findSelectValue(states, clientInfo.state_id) : undefined
                  }
                  error={clientInfo.state_id ? false
                    : !clientInfo.state_id || clientInfo.state_id === 0}
                  onChange={handleCountry}
                />
              </Grid>
              <Grid item md={4}>
                <FormSelect
                  label={t('city')}
                  options={cities}
                  value={
                    cities !== undefined
                      ? findSelectValue(cities, clientInfo.city_id) : undefined
                  }
                  error={clientInfo.city_id ? false
                    : !clientInfo.city_id || clientInfo.city_id === 0}
                  onChange={(e) => {
                    setValue('city_id', e.value);
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {(currentTabView === t('contacts') && editing) && (
        <Contacts />
      )}
      {currentTabView === t('legal-info') && (
        <LegalInfo
          clientInfo={clientInfo}
        />
      )}
      {currentTabView === t('notes') && (
        <Card id="notes" sx={{ overflow: 'visible' }}>
          <CardHeader
            title={t('notes')}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <SoftEditor
                  value={clientInfo.notes}
                  onChange={(e) => {
                    setValue('notes', e);
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {currentTabView === t('tags') && (
        <Tags
          setValue={(value) => setValue('recruitment_list', value)}
          list={clientInfo.recruitment_list}
        />
      )}
    </div>
  );
}

export default ClientInfo;
