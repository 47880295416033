import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Billing page components
import { checkPermission, currencyFormatter } from 'Util';
import Invoice from '../Invoice';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function Invoices({ invoices }) {
  const { t } = useTranslation('translation', { keyPrefix: 'accounts' });
  const { currentUser } = useSelector(selector, shallowEqual);
  const navigate = useNavigate();

  const handleNewInvoice = () => {
    navigate('/billing/invoices/new', { replace: true });
  };

  return (
    <Card>
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          {t('invoices')}
        </SoftTypography>
        {checkPermission('BIIN', currentUser) && (
          <SoftButton variant="text" color="dark" iconOnly onClick={handleNewInvoice}>
            <Icon>add</Icon>
          </SoftButton>
        )}
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {Object.keys(invoices).length > 0
            && invoices.map((b, i) => (
              <Invoice
                key={`invoice-${i}`}
                date={b.doc_date}
                id={`${b.doc_number}`}
                price={currencyFormatter.format(b.total)}
                status={t(b.status)}
                uuid={b.uuid}
              />
            ))}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

Invoices.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape(
      {
        doc_type: PropTypes.string,
        doc_number: PropTypes.number,
        status: PropTypes.string,
        doc_date: PropTypes.string,
        candidate_id: PropTypes.string,
        last_name: PropTypes.string,
        first_name: PropTypes.string,
        total: PropTypes.string,
        uuid: PropTypes.string,
      },
    ),
  ).isRequired,
};

export default Invoices;
