import { useTranslation } from 'react-i18next';

// @mui material components
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';

// DevExtreme components
import DataGrid, {
  ColumnChooser,
  ColumnFixing, Export,
  HeaderFilter, Item, LoadPanel, Pager, Paging,
  Scrolling, SearchPanel,
  Sorting, Toolbar,
} from 'devextreme-react/data-grid';

// Functions
import { currencyFormatter, renderColumns } from 'Util';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import PropTypes from 'prop-types';

function TransactionsTable({ transactions }) {
  const { t } = useTranslation('translation', { keyPrefix: 'accounts' });

  const allowedPageSizes = [10, 30, 50, 'all'];
  const name = 'Transactions';

  const handleExport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(name);

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${name}.xlsx`);
      });
    });
  };

  const handleType = (d) => {
    if (d === 'invoice') {
      return t('invoice');
    }
    if (d === 'payment') {
      return t('payment');
    }
    if (d === 'expense') {
      return t('expense');
    }
    return d;
  };

  const getGridCellData = (gridData) => gridData.data[gridData.column.dataField.toLowerCase()];
  const getCellColor = (transaction) => {
    if (transaction === 'invoice') {
      return '#df0202';
    }
    if (transaction === 'payment') {
      return '#0fb823';
    }
    return '#000000';
  };

  const amountCell = (cellData) => {
    const gridCellData = getGridCellData(cellData);
    return (
      <div style={{ color: getCellColor(gridCellData.tx) }}>
        <div>{currencyFormatter.format(gridCellData.value)}</div>
      </div>
    );
  };

  const handleVendor = (d) => {
    if (d.vendor === 'Invoice') {
      return `${d.first_name} ${d.last_name}`;
    }
    if (d.vendor === 'Payment') {
      return 'NAS';
    }
    return d.vendor;
  };

  const columns = [
    {
      caption: t('date'),
      field: 'doc_date',
      dataType: 'date',
      sortOrder: 'desc',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('type'),
      field: 'doc_type',
      calculateCellValue: (d) => handleType(d.doc_type),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('name'),
      field: 'vendor',
      calculateCellValue: (d) => handleVendor(d),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('amount'),
      field: 'amount',
      cellRender: amountCell,
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('balance'),
      field: 'balance',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
      visible: true,
      allowFixing: true,
    },
  ];

  return (
    <Card style={{ width: '100%' }}>
      <Paper>
        <DataGrid
          id={name}
          dataSource={transactions}
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onExporting={handleExport}
        >
          <ColumnFixing enabled />
          <HeaderFilter visible />
          <Scrolling rowRenderingMode="virtual" columnRenderingMode="virtual" />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={30} />
          <Pager
            visible
            allowedPageSizes={allowedPageSizes}
            displayMode="full"
            showPageSizeSelector
            showInfo
            showNavigationButtons
          />
          <LoadPanel enabled />
          <SearchPanel
            visible
            width={240}
            placeholder=""
          />
          <Export enabled />
          <ColumnChooser enabled mode="select" />
          {renderColumns(columns)}
          <Toolbar>
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </Paper>
    </Card>
  );
}

TransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape(
      {
        doc_type: PropTypes.string,
        doc_number: PropTypes.number,
        status: PropTypes.string,
        doc_date: PropTypes.string,
        candidate_id: PropTypes.string,
        last_name: PropTypes.string,
        first_name: PropTypes.string,
        total: PropTypes.string,
        vendor: PropTypes.string,
      },
    ),
  ).isRequired,
};

export default TransactionsTable;
