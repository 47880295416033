import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';

function StatsInfoCard({
  title = '',
  description = '',
  caption = '',
  icon = '',
}) {
  return (
    <Card>
      <Grid container sx={{ m: 2 }}>
        <Grid item md={12}>
          <SoftTypography variant="subtitle2" fontFamily="sans-serif">{title}</SoftTypography>
        </Grid>
        <Grid item md={10}>
          <SoftTypography variant="h3" fontFamily="sans-serif">{description}</SoftTypography>
        </Grid>
        <Grid item md={2}>
          {icon}
        </Grid>
        <Grid item md={12}>
          {caption}
        </Grid>
      </Grid>
    </Card>
  );
}

StatsInfoCard.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  description: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  caption: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.node,
};

export default StatsInfoCard;
