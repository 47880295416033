import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import {
  createCandidateNote,
  fetchCandidateNotes, setCreated, setFailed, setSaved,
  updateCandidateNote,
} from 'reducers/candidatesSlice';
import { fetchNoteSections } from 'reducers/commonSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftEditor from 'components/SoftEditor';

// Functions
import { findSelectValue, getCandidateUUID, setTitle } from 'Util';

// Components
import NoteTimelineItem from 'layouts/components/Timeline/NoteTimelineItem';
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';

// Other components
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

dayjs.extend(require('dayjs/plugin/localizedFormat'));

const selector = (state) => ({
  editing: state.candidate.editing,
  notes: state.candidate.notes,
  failed: state.candidate.failed,
  created: state.candidate.created,
  saved: state.candidate.saved,
  errors: state.candidate.errors,
  noteSections: state.common.noteSections,
});

function Notes() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.notes' });
  const {
    editing,
    notes,
    failed,
    created,
    saved,
    errors,
    noteSections,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(fetchNoteSections());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateNotes({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Notes');

  const [showNoteForm, setShowNoteForm] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [noteTitle, setNoteTitle] = useState('');
  const [noteContent, setNoteContent] = useState('');
  const [availableNoteSections, setAvailableNoteSections] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [filterSection, setFilterSection] = useState(0);

  useEffect(() => {
    const sections = [];
    sections.push({ value: 0, label: 'All' });
    noteSections.forEach((mt) => sections.push(mt));
    setAvailableNoteSections(sections);
  }, [noteSections]);

  useEffect(() => {
    if (notes) {
      setFilterSection(0);
      setFilteredNotes(notes);
    }
  }, [notes]);

  const handleNewNote = () => {
    setNoteId(null);
    setNoteTitle('');
    setNoteContent('');
    setShowNoteForm(true);
  };
  const handleCloseNoteForm = () => setShowNoteForm(false);
  const handleSelect = (e, i) => {
    e.preventDefault();
    const n = notes[i];
    setNoteId(n.id);
    setNoteTitle(n.title);
    setNoteContent(n.note);
    setShowNoteForm(true);
  };
  const handleSaveNote = () => {
    if ((!noteTitle || noteTitle !== '') && (!noteContent || noteContent !== '')) {
      setOpenBackdrop(true);

      const data = {
        candidateId: uuid,
        title: noteTitle,
        note: noteContent,
      };

      if (noteId !== null) {
        data.id = noteId;
        dispatch(updateCandidateNote(data));
      } else {
        data.date = Date.parse(Date()
          .toString())
          .toString('u')
          .replace('Z', ' UTC');
        data.noteSectionId = 1;

        dispatch(createCandidateNote(data));
      }

      setShowNoteForm(false);
    }
  };
  const filterNoteSection = (e) => {
    setFilterSection(e.value);
    if (e.value === 0) {
      setFilteredNotes(notes);
    } else {
      setFilteredNotes(notes
        .filter((n) => (n.note_section ? n.note_section.id === e.value : false)));
    }
  };

  if (created) {
    setOpenBackdrop(false);
    dispatch(setCreated(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('candidate-created-successfully', { keyPrefix: 'candidates.save' }),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }
  if (saved) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('candidate-saved-successfully', { keyPrefix: 'candidates.save' }),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }
  if (failed) {
    setOpenBackdrop(false);
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errors,
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      mt={3}
      mb={3}
    >
      <Grid item md={4}>
        <FormSelect
          label={t('section')}
          options={availableNoteSections}
          value={findSelectValue(availableNoteSections, filterSection)}
          onChange={filterNoteSection}
        />
      </Grid>
      <Grid item md={8}>
        <SoftBox display="flex" justifyContent="flex-end">
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleNewNote}
          >
            <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            &nbsp;
            {t('new-note')}
          </SoftButton>
        </SoftBox>
      </Grid>
      <Grid item md={12}>
        {filteredNotes
          && filteredNotes.map((n, i) => (
            <NoteTimelineItem
              key={`note${i}`}
              color="info"
              icon="sticky_note_2"
              title={n.title}
              dateTime={dayjs(n.date).format('LLL')}
              description={n.note}
              badges={[`${n.user.first_name} ${n.user.last_name}`]}
              outcome={n.note_section ? n.note_section.name : ''}
              linkHandler={(e) => handleSelect(e, i)}
            />
          ))}
      </Grid>
      <Dialog
        open={showNoteForm}
        onClose={handleCloseNoteForm}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Note</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item md={12}>
              <FormField
                id="title"
                label="title"
                value={noteTitle !== undefined ? noteTitle : ''}
                error={!noteTitle || noteTitle === ''}
                onChange={(e) => setNoteTitle(e.target.value)}
              />
            </Grid>
            <Grid item md={12}>
              <SoftEditor
                value={noteContent}
                onChange={setNoteContent}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SoftButton variant="outlined" color="info" onClick={handleCloseNoteForm}>
            {t('close', { keyPrefix: 'common' })}
          </SoftButton>
          <SoftButton
            variant="contained"
            color="info"
            onClick={handleSaveNote}
          >
            {t('save', { keyPrefix: 'common' })}
          </SoftButton>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Notes;
