import Service from './service';

class CommonService extends Service {
  async getEducationLevels() {
    const response = await this.api
      .get(
        this.localizeURL('education-levels'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getLanguages() {
    const response = await this.api
      .get(
        this.localizeURL('languages'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getCountries() {
    const response = await this.api
      .get(
        this.localizeURL('countries'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getStates(id) {
    const response = await this.api
      .get(
        this.localizeURL(`countries/${id}/states`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getCities(countryId, stateId) {
    const response = await this.api
      .get(
        this.localizeURL(`countries/${
          countryId
        }/states/${
          stateId
        }/cities`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getAddressTypes() {
    const response = await this.api
      .get(
        this.localizeURL('address_types'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getCitizenships() {
    const response = await this.api
      .get(
        this.localizeURL('citizenships'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getCandidateStatuses() {
    const response = await this.api
      .get(
        this.localizeURL('candidate-statuses'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getContactMethods() {
    const response = await this.api
      .get(
        this.localizeURL('contact_methods'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getContactOutcomes() {
    const response = await this.api
      .get(
        this.localizeURL('contact_outcomes'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getLanguageProficiencies() {
    const response = await this.api
      .get(
        this.localizeURL('language-proficiency'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getLanguageProficiencyTests(id) {
    const response = await this.api
      .get(
        this.localizeURL(`language-proficiency/${id}/tests`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getRaces() {
    const response = await this.api
      .get(
        this.localizeURL('races'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getLicenseTypes() {
    const response = await this.api
      .get(
        this.localizeURL('license-types'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getTimezones() {
    const response = await this.api
      .get(
        this.localizeURL('timezones'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getClinicalExperiences() {
    const response = await this.api
      .get(
        this.localizeURL('clinical-experiences'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getCredentialEvaluators() {
    const response = await this.api
      .get(
        this.localizeURL('credential-evaluators'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getAgreementTypes() {
    const response = await this.api
      .get(
        this.localizeURL('agreement-types'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getDocumentTypes() {
    const response = await this.api
      .get(
        this.localizeURL('document-types'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getMeetingTypes() {
    const response = await this.api
      .get(
        this.localizeURL('meeting-types'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getMeetingOutcomes() {
    const response = await this.api
      .get(
        this.localizeURL('meeting-outcomes'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getRoleTypes() {
    const response = await this.api
      .get(
        this.localizeURL('role-types'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getVisaScreenAgencies() {
    const response = await this.api
      .get(
        this.localizeURL('visa-screen-agencies'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getTemplateTypes() {
    const response = await this.api
      .get(
        this.localizeURL('template-types'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getTags(id) {
    const response = await this.api
      .get(
        this.localizeURL(`tags/${id}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getNoteSections() {
    const response = await this.api
      .get(
        this.localizeURL('note-sections'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }
}

export default new CommonService();
