import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Chip } from '@mui/material';
import Card from '@mui/material/Card';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import GroupsIcon from '@mui/icons-material/Groups';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { styled } from '@mui/material/styles';

import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';

import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { findSelectValue } from 'Util';
import { useEffect, useState } from 'react';

const selector = (state) => ({
  meetingTypes: state.common.meetingTypes,
  contactMethods: state.common.contactMethods,
});

function EventInfoCard({ event = null, openFunc }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.events' });
  const {
    meetingTypes,
    contactMethods,
  } = useSelector(selector, shallowEqual);

  const [meetingTypeName, setMeetingTypeName] = useState('');
  const [contactMethodName, setContactMethodName] = useState('');

  useEffect(() => {
    if (event && meetingTypes && meetingTypes.length > 0) {
      const meetingType = findSelectValue(meetingTypes, event.metadata.meeting_type_id);
      if (meetingType) {
        if (meetingType.label) {
          setMeetingTypeName(meetingType.label);
        }
      }
    }
  }, [event, meetingTypes]);

  useEffect(() => {
    if (event && contactMethods && contactMethods.length > 0) {
      const contactMethod = findSelectValue(contactMethods, event.metadata.contact_method_id);
      if (contactMethod) {
        if (contactMethod.label) {
          setContactMethodName(contactMethod.label);
        }
      }
    }
  }, [contactMethods, event]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 600,
      fontSize: theme.typography.pxToRem(18),
      border: '1px solid #dadde9',
    },
  }));

  const eventTypeMap = {
    1: { label: t('meeting'), color: 'primary', icon: <GroupsIcon /> },
    2: { label: t('communication'), color: 'success', icon: <ConnectWithoutContactIcon /> },
    3: { label: t('reminder'), color: 'error', icon: <NotificationsActiveIcon /> },
  };

  const renderEventType = (type) => {
    if (!type || !eventTypeMap[type]) return '';
    const { label, color, icon } = eventTypeMap[type];
    return (<Chip variant="outlined" color={color} label={label} icon={icon} />);
  };

  const handleOpenEvent = (e) => {
    e.preventDefault();
    openFunc(event.id);
  };

  return (
    <Card onDoubleClick={handleOpenEvent}>
      <HtmlTooltip title={parse(event.description)} followCursor>
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
          m={2}
        >
          <Grid item md={1}>
            <Grid
              container
              direction="column"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item>
                {dayjs(event.start_date).format('ddd')}
              </Grid>
              <Grid item>
                <SoftTypography variant="h1">
                  {dayjs(event.start_date).format('DD')}
                </SoftTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item md={12} p={1}>
                <SoftTypography variant="subtitle2">
                  <AccessTimeIcon style={{ paddingTop: '3px' }} />
                  {` ${dayjs(event.start_date).format('hh:mm A')} - ${dayjs(event.end_date).format('hh:mm A')}`}
                </SoftTypography>
              </Grid>
              <Grid item md={12} p={1}>
                {renderEventType(event.event_type)}
                &nbsp;
                {(meetingTypes && event.event_type === 1) && (
                  <Chip variant="outlined" color="secondary" label={meetingTypeName} />
                )}
                {(contactMethods && event.event_type === 2) && (
                  <Chip variant="outlined" color="info" label={contactMethodName} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={8}>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item md={12}>
                <SoftTypography variant="subtitle1">
                  {event.subject}
                </SoftTypography>
              </Grid>
              <Grid item md={12}>
                <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      {t('attendees')}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                {event.attendees.map((attendee) => (
                  <Chip label={attendee.name} color="secondary" sx={{ ml: 1 }} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </HtmlTooltip>
    </Card>
  );
}

EventInfoCard.propTypes = {
  // eslint-disable-next-line react/require-default-props
  event: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.number,
    end_date: PropTypes.string,
    event_type: PropTypes.string,
    subject: PropTypes.string,
    attendees: PropTypes.arrayOf(),
  }),
  // eslint-disable-next-line react/require-default-props
  openFunc: PropTypes.func,
};

export default EventInfoCard;
