function coachColumns(t) {
  return (
    [
      {
        caption: t('first-name', { keyPrefix: 'common' }),
        field: 'first_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('last-name', { keyPrefix: 'common' }),
        field: 'last_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('current-coach', { keyPrefix: 'candidates.advisors' }),
        field: 'current_coach_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('coaching-sessions'),
        field: 'meetings',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('e-mail', { keyPrefix: 'candidates.contact-info' }),
        field: 'default_email',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('phone', { keyPrefix: 'candidates.contact-info' }),
        field: 'default_phone',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('current-advisor', { keyPrefix: 'candidates.basic-info' }),
        field: 'current_advisor_name',
        visible: false,
        allowFixing: true,
      },
    ]);
}

export default coachColumns;
