import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Reducer Functions
import { fetchCandidatesOutlook, setGoToViewSubView } from 'reducers/candidatesSlice';

// Soft UI Dashboard PRO React components
import SoftButton from 'components/SoftButton';

// @mui material components
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Other components
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

// DevExtreme components
import DataGrid, {
  ColumnChooser,
  ColumnFixing,
  Editing,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';

// Layout components
import BaseLayout from 'layouts/components/BaseLayout';
import TabBar from 'layouts/components/TabBar';

// Functions
import { renderColumns, setTitle } from 'Util';

// View columns
import advisorColumns from './columns/advisorColumns';
import recruiterColumns from './columns/recruiterColumns';
import coachColumns from './columns/coachColumns';

const selector = (state) => ({
  candidates: state.candidate.candidatesOutlook,
  user: state.auth.user,
});

function Candidates({
  statuses = '*',
  enable = null,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.list' });
  const {
    candidates,
    user,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [selectedTab, setSelectedTab] = useState('assigned');

  setTitle('Candidates');

  const tabs = [];
  tabs.push(t('assigned-to-me'));

  if (user.role.all_users || user.role.role_type_id === 1) {
    tabs.push(t('all'));
  }

  useEffect(() => {
    if (selectedTab === 'assigned') {
      setFilteredCandidates(candidates.filter((c) => c.current_advisor_uuid.includes(user.uuid)));
    } else {
      setFilteredCandidates(candidates);
    }
  }, [candidates, user, selectedTab]);

  useEffect(() => {
    dispatch(fetchCandidatesOutlook({
      statuses,
      enable,
    }));
  }, [dispatch, statuses, enable]);

  useEffect(() => {
    dispatch(setGoToViewSubView({
      view: 'overview',
      subview: undefined,
    }));
  }, [dispatch]);

  const dataGrid = useRef(null);
  const allowedPageSizes = [10, 30, 50, 'all'];
  const exportName = 'Candidates';

  const handleSetTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('all'):
        setSelectedTab('all');
        break;
      case t('assigned-to-me'):
        setSelectedTab('assigned');
        break;
      default:
        setSelectedTab('assigned');
        break;
    }
  };
  const handleEditing = (e) => {
    navigate(`/candidates/edit/${e.key}`);
  };
  const handleExport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(exportName);

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${exportName}.xlsx`);
      });
    });
  };
  const handleStateReset = () => {
    dataGrid.current.instance.state(null);
  };

  return (
    <BaseLayout>
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetTabValue}
      />
      <Grid
        container
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <SoftButton variant="text" color="info" onClick={handleStateReset}>
            {t('reset-filters', { keyPrefix: 'common' })}
          </SoftButton>
        </Grid>
      </Grid>
      <Paper>
        <DataGrid
          id="candidates"
          ref={dataGrid}
          dataSource={filteredCandidates}
          keyExpr="uuid"
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onEditingStart={handleEditing}
          onExporting={handleExport}
        >
          <ColumnChooser enabled />
          <ColumnFixing enabled />
          <Editing
            mode="row"
            allowUpdating
            allowDeleting={false}
            allowAdding={false}
          />
          <Export
            enabled
          />
          <HeaderFilter visible />
          <Scrolling rowRenderingMode="virtual" />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={30} />
          <Pager
            visible
            allowedPageSizes={allowedPageSizes}
            displayMode="full"
            showPageSizeSelector
            showInfo
            showNavigationButtons
          />
          <SearchPanel
            visible
            width={240}
            placeholder=""
          />
          <StateStoring
            enabled
            type="localStorage"
            storageKey="candidates-grid"
          />
          {user.role.role_type_id === 1 && renderColumns(advisorColumns(t))}
          {user.role.role_type_id === 3 && renderColumns(advisorColumns(t))}
          {user.role.role_type_id === 4 && renderColumns(advisorColumns(t))}
          {user.role.role_type_id === 5 && renderColumns(recruiterColumns(t))}
          {user.role.role_type_id === 6 && renderColumns(coachColumns(t))}
        </DataGrid>
      </Paper>
    </BaseLayout>
  );
}

Candidates.propTypes = {
  // eslint-disable-next-line react/require-default-props
  statuses: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  enable: PropTypes.bool,
};

export default Candidates;
