import Service from './service';

class RecruiterService extends Service {
  async getRecruiters() {
    const response = await this.api
      .get(
        'recruiters',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async getRecruiter(id) {
    const response = await this.api
      .get(
        `recruiters/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createRecruiter(name, description, code) {
    const data = {
      recruiter: {
        name,
        description,
        code,
      },
    };

    const response = await this.api
      .post(
        'recruiters',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateRecruiter(id, name, description, code) {
    const data = {
      recruiter: {
        name,
        description,
        code,
      },
    };

    const response = await this.api
      .put(
        `recruiters/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteRecruiter(id) {
    const response = await this.api
      .delete(
        `recruiters/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async getRecruiterContacts(id) {
    const response = await this.api
      .get(
        this.localizeURL(`recruiters/${id}/contacts`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  async createRecruiterContact(
    recruiterId,
    name,
    position,
    email,
    phone,
  ) {
    const data = {
      contact: {
        name,
        position,
        email,
        phone,
      },
    };

    const response = await this.api
      .post(
        `recruiters/${recruiterId}/contacts`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async updateRecruiterContact(
    id,
    recruiterId,
    name,
    position,
    email,
    phone,
  ) {
    const data = {
      contact: {
        name,
        position,
        email,
        phone,
      },
    };

    const response = await this.api
      .put(
        `recruiters/${recruiterId}/contacts/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async deleteRecruiterContact(recruiterId, id) {
    const response = await this.api
      .delete(
        `recruiters/${recruiterId}/contacts/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new RecruiterService();
