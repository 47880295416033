import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import RoleService from 'services/role.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  generateDropboxProperties,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchRoles = createAsyncThunk(
  'role/fetchRoles',
  async (payload, { rejectWithValue }) => {
    try {
      return await RoleService.getRoles();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchRole = createAsyncThunk(
  'role/fetchRole',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await RoleService.getRole(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createRole = createAsyncThunk(
  'role/createRole',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      allUsers,
      dashboardId,
      roleTypeId,
      rolePermissions,
      statuses,
      notes,
      meetingTypes,
    } = payload;
    try {
      return await RoleService
        .createRole(
          name,
          allUsers,
          dashboardId,
          roleTypeId,
          rolePermissions,
          statuses,
          notes,
          meetingTypes,
        );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateRole = createAsyncThunk(
  'role/updateRole',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      allUsers,
      dashboardId,
      roleTypeId,
      rolePermissions,
      statuses,
      notes,
      meetingTypes,
    } = payload;
    try {
      return await RoleService.updateRole(
        id,
        name,
        allUsers,
        dashboardId,
        roleTypeId,
        rolePermissions,
        statuses,
        notes,
        meetingTypes,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteRole = createAsyncThunk(
  'role/deleteRole',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await RoleService.deleteRole(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    roleInfo: {
      name: '',
      all_users: false,
      dashboard_id: 1,
      role_permissions: [],
    },
    roles: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearRole: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.roleInfo = {
        name: '',
        all_users: false,
        dashboard_id: 1,
        role_type_id: null,
        role_permissions: [],
        statuses: null,
      };
    },
    setRoleEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setRoleInfo: (state, action) => {
      state.roleInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'role') {
        switch (performedAction[1]) {
          case 'fetchRoles':
            fulfilledReducer(state, action);
            state.roles = generateDropboxProperties(action);
            break;
          case 'fetchRole':
            fulfilledReducer(state, action);
            state.roleInfo = action.payload;
            break;
          case 'createRole':
            fulfilledCreatedReducer(state, action);
            break;
          case 'updateRole':
          case 'deleteRole':
            fulfilledSavedReducer(state, action);
            state.roleInfo = action.payload.data;
            break;
          default:
            fulfilledReducer(state, action);
            state.message = action.payload;
            break;
        }
      }
    },
  ),
});

export const {
  clearRole,
  setRoleEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setRoleInfo,
} = roleSlice.actions;

export default roleSlice.reducer;
