import { Link } from 'react-router-dom';

// html parser
import parse from 'html-react-parser';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftBadge from 'components/SoftBadge';

// Timeline context
import { useTimeline } from '../context';

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from './styles';

function NoteTimelineItem({
  color, icon, title, dateTime, description, outcome, badges, lastItem, linkHandler,
}) {
  const isDark = useTimeline();

  const renderBadges = badges.length > 0
    ? badges.map((badge, key) => {
      const badgeKey = `badge-${key}`;

      return (
        <SoftBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5} sx={{ zIndex: 0 }}>
          <SoftBadge color={color} size="xs" badgeContent={badge} container />
        </SoftBox>
      );
    })
    : null;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link to="#" onClick={linkHandler}>
      <SoftBox position="relative" sx={(theme) => timelineItem(theme, { lastItem })}>
        <SoftBox
          bgColor={isDark ? 'dark' : 'white'}
          width="1.625rem"
          height="1.625rem"
          borderRadius="50%"
          position="absolute"
          top="3.25%"
          left="2px"
          zIndex={0}
        >
          <Icon sx={(theme) => timelineItemIcon(theme, { color })}>{icon}</Icon>
        </SoftBox>
        <SoftBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0}>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <SoftTypography variant="body1" fontWeight="medium" color={isDark ? 'white' : 'dark'}>
              {title}
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="medium" color={isDark ? 'white' : 'dark'}>
              {outcome && (<SoftBadge color="secondary" size="xs" badgeContent={outcome} container />)}
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={0.5}>
            <SoftTypography
              variant="caption"
              fontWeight="medium"
              color={isDark ? 'secondary' : 'text'}
            >
              {dateTime}
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={2} mb={1.5}>
            {description ? (
              <SoftTypography variant="body2" fontWeight="regular" color="text">
                {parse(description)}
              </SoftTypography>
            ) : null}
          </SoftBox>
          {badges.length > 0 ? (
            <SoftBox display="flex" pb={lastItem ? 1 : 2}>
              {renderBadges}
            </SoftBox>
          ) : null}
        </SoftBox>
      </SoftBox>
    </Link>
  );
}

// Setting admin values for the props of NoteTimelineItem
NoteTimelineItem.defaultProps = {
  color: 'info',
  badges: [],
  lastItem: false,
  description: '',
  outcome: '',
  linkHandler: undefined,
};

// Typechecking props for the NoteTimelineItem
NoteTimelineItem.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  outcome: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool,
  linkHandler: PropTypes.func,
};

export default NoteTimelineItem;
