import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import {
  fetchCandidateExperienceDocuments,
  fetchCandidateExperiences,
  setCurrentSubView,
} from 'reducers/candidatesSlice';
import {
  fetchClinicalExperiences,
  fetchCountries,
} from 'reducers/commonSlice';
import { fetchDocuments } from 'reducers/documentsSlice';
import { clearTokenLink } from 'reducers/tokenLinksSlice';

// Functions
import { checkPermission, getCandidateUUID, setTitle } from 'Util';

// Components
import ExperienceInfo from './components/ExperienceInfo';
import NotesWidget from '../NotesWidget';

// Other components
import 'assets/styles/phone-input.css';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
});

function Experience() {
  const {
    currentUser,
    editing,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('experience'));
    dispatch(fetchCountries());
    dispatch(fetchClinicalExperiences());
    dispatch(fetchDocuments());
    dispatch(clearTokenLink());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateExperiences({ uuid }));
      dispatch(fetchCandidateExperienceDocuments({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Work Experience');

  return (
    <>
      <ExperienceInfo
        uuid={uuid}
      />
      {(checkPermission('CANT', currentUser)) && (
        <NotesWidget section={6} />
      )}
    </>
  );
}

export default Experience;
