import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  assignCandidateRecruitmentDocument,
  deleteCandidateRecruitment,
  deleteCandidateRecruitmentDocument,
  fetchCandidateProfile,
  fetchCandidateRecruitment,
  setFailed,
  setSaved,
  updateCandidateRecruitment,
  uploadCandidateRecruitmentDocument,
} from 'reducers/candidatesSlice';
import { fetchDocuments } from 'reducers/documentsSlice';
import { fetchAttorney } from 'reducers/attorneySlice';

// @material-ui core components
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftEditor from 'components/SoftEditor';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import ApplicationPositionCard
  from 'layouts/components/Cards/PositionCards/ApplicationPositionCard';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';

// Functions
import {
  defaultValue,
  failedCandidateMessage,
  findSelectValue,
  getCandidateUUID,
  savedCandidateMessage,
} from 'Util';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { Backdrop, Chip, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TabBar from '../../../components/TabBar';

const selector = (state) => ({
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  recruitments: state.candidate.recruitments,
  documents: state.document.documents,
  attorneyInfo: state.attorney.attorneyInfo,
});

function JobApplications2() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.job-applications' });
  const {
    saved,
    failed,
    errors,
    recruitments,
    documents,
    attorneyInfo,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [position, setPosition] = useState({});
  const [recruitment, setRecruitment] = useState({ recruitment_stage_id: 0 });
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [availDocs, setAvailDocs] = useState([]);
  const [assignedDocs, setAssignedDocs] = useState([]);
  const [attorneyContacts, setAttorneyContacts] = useState([]);
  const [showApplication, setShowApplication] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const stages = [
    {
      value: 1,
      label: t('selection'),
    },
    {
      value: 2,
      label: t('screened'),
    },
    {
      value: 3,
      label: t('interview'),
    },
    {
      value: 4,
      label: t('offered'),
    },
    {
      value: 5,
      label: t('hired'),
    },
    {
      value: 6,
      label: t('arrived'),
    },
    {
      value: 7,
      label: t('rejected'),
    },
  ];

  const offerStatuses = [
    {
      value: 1,
      label: t('pending'),
    },
    {
      value: 2,
      label: t('rejected'),
    },
    {
      value: 3,
      label: t('accepted'),
    },
  ];

  const rejectBy = [
    {
      value: 1,
      label: t('candidate'),
    },
    {
      value: 2,
      label: t('client'),
    },
  ];

  const tabs = stages.map((stage) => stage.label);

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchCandidateRecruitment({ uuid }));
  }, [dispatch, uuid]);

  useEffect(() => {
    if (recruitment.documents !== undefined) {
      setAvailDocs(documents
        .filter((doc) => doc.document_type_id === 13)
        .filter((ad) => !recruitment.documents.some((cd) => ad.id === cd.document.id)));
      setAssignedDocs(recruitment.documents);
    }
  }, [documents, recruitment]);

  useEffect(() => {
    if (recruitment.id !== undefined) {
      const r = recruitments
        .find((obj) => obj.id === recruitment.id);
      if (r !== undefined) {
        setRecruitment(recruitments
          .find((obj) => obj.id === recruitment.id));
      } else {
        setRecruitment({ recruitment_stage_id: 0 });
      }
    }
  }, [recruitment.id, recruitments]);

  useEffect(() => {
    if (attorneyInfo !== undefined && attorneyInfo.contacts !== undefined) {
      setAttorneyContacts(
        attorneyInfo.contacts
          .map((c) => ({ value: c.id, label: `${c.name} - ${c.position}` })),
      );
    }
  }, [attorneyInfo]);

  const setValue = (key, value) => {
    if (key === 'recruitment_stage_id' && value !== 4) {
      setRecruitment({
        ...recruitment,
        [key]: value,
        offer_status_id: recruitment.offer_status_id === 2 ? null : recruitment.offer_status_id,
        changed: true,
      });
    } else if (key === 'offer_status_id' && value === 2) {
      setRecruitment({
        ...recruitment,
        [key]: value,
        rejected_in_stage: recruitment.recruitment_stage_id,
        changed: true,
      });
    } else {
      setRecruitment({
        ...recruitment,
        [key]: value,
        changed: true,
      });
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleShowApplication = (e, id) => {
    e.preventDefault();
    const pos = recruitments.find((p) => p.id === id);
    setPosition(pos.position_listing);
    setRecruitment(pos);
    if (pos.position_listing.client_attorney !== undefined
      && pos.position_listing.client_attorney !== null) {
      dispatch(fetchAttorney({ id: pos.position_listing.client_attorney }));
    }
    console.log(pos.recruitment_stage_id);
    setSelectedTab(pos.recruitment_stage_id - 1);
    setShowApplication(true);
  };

  const handleClosePosition = () => {
    setRecruitment({});
    setShowApplication(false);
  };

  const handleSave = () => {
    if (recruitment.id !== undefined) {
      setOpenBackdrop(true);
      dispatch(updateCandidateRecruitment({
        id: recruitment.id,
        candidateId: uuid,
        positionListingId: recruitment.position_listing_id,
        recruitmentStageId: recruitment.offer_status_id === 2
          ? 7 : recruitment.recruitment_stage_id,
        startDate: recruitment.start_date,
        clientInfoSentOn: recruitment.client_info_sent_on,
        sentToClientOn: recruitment.sent_to_client_on,
        preparationEmail: recruitment.preparation_email,
        preparationInterview: recruitment.preparation_interview,
        interviewDate: recruitment.interview_date,
        offerReceivedDate: recruitment.offer_received_date,
        offerStatusId: recruitment.offer_status_id,
        offerAcceptanceDate: recruitment.offer_acceptance_date,
        offerRejectedBy: recruitment.offer_rejected_by,
        rejectedOn: recruitment.rejected_on,
        rejectedInStage: recruitment.rejected_in_stage,
        expectedStartingDate: recruitment.expected_starting_date,
        actualStartingDate: recruitment.actual_starting_date,
        flightDate: recruitment.flight_date,
        attorneyContactId: recruitment.attorney_contact_id,
        rejectionNotes: recruitment.rejection_notes,
        wages: recruitment.wages,
        unit: recruitment.unit,
      }));
      handleClosePosition();
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('are-you-sure-you-want-to-delete-it', { keyPrefix: 'common' }),
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then(() => {
      handleClosePosition(false);
      setOpenBackdrop(true);
      dispatch(deleteCandidateRecruitment({
        id: recruitment.id,
        candidateId: uuid,
      }));
    });
  };

  const handleAddDocument = () => {
    if (recruitment !== undefined) {
      dispatch(assignCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: selectedDoc,
      }));
      setSelectedDoc(null);
    }
  };

  const handleDeleteDocument = (id) => {
    if (recruitment !== undefined) {
      dispatch(deleteCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: id,
      }));
    }
  };

  const handleUploadDocument = (files, id) => {
    if (recruitment !== undefined) {
      dispatch(uploadCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: id,
        file: files[0],
      }));
    }
  };

  const handleRejectedByCandidate = () => {
    setRecruitment({
      ...recruitment,
      recruitment_stage_id: 7,
      offer_rejected_by: 1,
      rejected_on: dayjs().format('YYYY-MM-DD'),
      rejected_in_stage: 1,
      changed: true,
    });
  };

  const handleRejectedByClientAtScreened = () => {
    setRecruitment({
      ...recruitment,
      recruitment_stage_id: 7,
      offer_rejected_by: 2,
      rejected_on: dayjs().format('YYYY-MM-DD'),
      rejected_in_stage: 2,
      changed: true,
    });
  };

  const handleRejectedByClientAtInterviewed = () => {
    setRecruitment({
      ...recruitment,
      recruitment_stage_id: 7,
      offer_rejected_by: 2,
      rejected_on: dayjs().format('YYYY-MM-DD'),
      rejected_in_stage: 3,
      changed: true,
    });
  };

  const handleOfferStatus = (value) => {
    if (value === 2) {
      setRecruitment({
        ...recruitment,
        offer_status_id: value,
        rejected_in_stage: 4,
        offer_rejected_by: 1,
        changed: true,
      });
    } else {
      setRecruitment({
        ...recruitment,
        offer_status_id: value,
        rejected_in_stage: null,
        offer_rejected_by: null,
        changed: true,
      });
    }
  };

  if (saved) {
    dispatch(fetchCandidateProfile({ uuid }));
    setOpenBackdrop(false);
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    setOpenBackdrop(false);
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <SoftBox p={2}>
      {showApplication && (
        <div>
          <Card sx={{ overflow: 'visible' }}>
            <Grid
              container
              direction="row"
              spacing={1}
              p={2}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Grid item md={12}>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleClosePosition}
                  >
                    {t('back', { keyPrefix: 'common' })}
                  </Button>
                </Stack>
                <Divider />
              </Grid>
              <Grid item md={9}>
                <SoftTypography
                  variant="h6"
                >
                  {t('position')}
                </SoftTypography>
                <SoftTypography
                  variant="h4"
                >
                  {position.name}
                </SoftTypography>
              </Grid>
              <Grid item md={3}>
                <FormSelect
                  label={t('stage')}
                  options={stages}
                  value={
                    findSelectValue(stages, recruitment.recruitment_stage_id)
                  }
                  onChange={(e) => {
                    setSelectedTab(e.value - 1);
                    setValue('recruitment_stage_id', e.value);
                  }}
                />
              </Grid>
              <Grid item md={3.5}>
                <SoftTypography
                  variant="h6"
                >
                  {t('client')}
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                >
                  {position.client_name}
                </SoftTypography>
              </Grid>
              <Grid item md={2.5}>
                <SoftTypography variant="h6">{t('city', { keyPrefix: 'common' })}</SoftTypography>
                <SoftTypography variant="h5">{position.city}</SoftTypography>
              </Grid>
              <Grid item md={2.5}>
                <SoftTypography variant="h6">{t('state', { keyPrefix: 'common' })}</SoftTypography>
                <SoftTypography variant="h5">{position.state}</SoftTypography>
              </Grid>
              <Grid item md={3.5}>
                <Stack spacing={1} direction="row">
                  {Object.keys(position.tags).length > 0
                    && position.tags.map((tag) => (
                      <Chip label={tag} />
                    ))}
                </Stack>
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid item md={12}>
                <TabBar
                  tabs={tabs}
                  setTabValueFunction={handleSetTabValue}
                  defaultTabValue={selectedTab}
                  sx={{ zIndex: 0 }}
                />
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>
              {selectedTab === 0 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('application-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('start_date', event.target.value);
                      }}
                      value={recruitment.start_date}
                    />
                  </Grid>
                  <Grid item md={10} />
                  <Grid item md={2}>
                    <FormField
                      label={t('client-info-sent-on')}
                      type="date"
                      onChange={(event) => {
                        setValue('client_info_sent_on', event.target.value);
                      }}
                      value={recruitment.client_info_sent_on}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-info-to-candidate')}
                    </SoftButton>
                  </Grid>
                  <Grid item md={5} />
                  <Grid item md={5}>
                    <SoftButton
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleRejectedByCandidate}
                    >
                      {t('rejected-by-candidate')}
                    </SoftButton>
                  </Grid>
                </>
              )}
              {selectedTab === 1 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('sent-to-client')}
                      type="date"
                      onChange={(event) => {
                        setValue('sent_to_client_on', event.target.value);
                      }}
                      value={recruitment.sent_to_client_on}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-to-client')}
                    </SoftButton>
                  </Grid>
                  <Grid item md={5} />
                  <Grid item md={5}>
                    <SoftButton
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleRejectedByClientAtScreened}
                    >
                      {t('rejected-by-client')}
                    </SoftButton>
                  </Grid>
                </>
              )}
              {selectedTab === 2 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('preparation-email')}
                      type="date"
                      onChange={(event) => {
                        setValue('preparation_email', event.target.value);
                      }}
                      value={defaultValue(recruitment.preparation_email)}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-preparation-email')}
                    </SoftButton>
                  </Grid>
                  <Grid item md={5} />
                  <Grid item md={2}>
                    <FormField
                      label={t('preparation-interview')}
                      type="date"
                      onChange={(event) => {
                        setValue('preparation_interview', event.target.value);
                      }}
                      value={defaultValue(recruitment.preparation_interview)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormField
                      label={t('interview-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('interview_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.interview_date)}
                    />
                  </Grid>
                  <Grid item md={8} />
                  <Grid item md={5}>
                    <SoftButton
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleRejectedByClientAtInterviewed}
                    >
                      {t('rejected-by-client')}
                    </SoftButton>
                  </Grid>
                </>
              )}
              {selectedTab === 3 && (
                <>
                  <Grid item md={3}>
                    <FormField
                      label={t('offer-received-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('offer_received_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.offer_received_date)}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormSelect
                      label={t('offer-status')}
                      placeholder={t('offer-status')}
                      options={offerStatuses}
                      value={
                        findSelectValue(offerStatuses, recruitment.offer_status_id)
                      }
                      onChange={(event) => handleOfferStatus(event.value)}
                    />
                  </Grid>
                  {recruitment.offer_status_id === 2 && (
                    <Grid item md={3}>
                      <FormField
                        label={t('rejected-on')}
                        type="date"
                        onChange={(event) => {
                          setValue('rejected_on', event.target.value);
                        }}
                        value={defaultValue(recruitment.rejected_on)}
                      />
                    </Grid>
                  )}
                </>
              )}
              {selectedTab === 4 && (
                <>
                  {recruitment.offer_status_id === 3 && (
                    <Grid item md={3}>
                      <FormField
                        label={t('offer-acceptance-date')}
                        type="date"
                        onChange={(event) => {
                          setValue('offer_acceptance_date', event.target.value);
                        }}
                        value={defaultValue(recruitment.offer_acceptance_date)}
                      />
                    </Grid>
                  )}
                  <Grid item md={3}>
                    <FormField
                      label={t('wage')}
                      onChange={(event) => {
                        setValue('wages', event.target.value);
                      }}
                      value={defaultValue(recruitment.wages)}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormField
                      label={t('unit')}
                      onChange={(event) => {
                        setValue('unit', event.target.value);
                      }}
                      value={defaultValue(recruitment.unit)}
                    />
                  </Grid>
                  <Grid item md={3} />
                  <Grid item md={6}>
                    {(position.client_attorney !== undefined
                      && position.client_attorney !== null) && (
                      <FormSelect
                        label={t('attorney-in-charge')}
                        placeholder={t('select-attorney')}
                        options={attorneyContacts}
                        value={
                          findSelectValue(attorneyContacts, recruitment.attorney_contact_id)
                        }
                        onChange={(event) => {
                          setValue('attorney_contact_id', event.value);
                        }}
                      />
                    )}
                  </Grid>
                  {recruitment.offer_status_id === 3 && (
                    <>
                      <Grid item md={9} />
                      {Object.keys(availDocs).length > 0 && (
                        <>
                          <Grid item md={4}>
                            <FormSelect
                              label={t('assign-document')}
                              placeholder={t('select-document')}
                              options={availDocs}
                              onChange={(e) => {
                                setSelectedDoc(e.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={3} mt={5}>
                            <IconButton
                              aria-label="add"
                              onClick={handleAddDocument}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                        </>
                      )}
                      {Object.keys(assignedDocs).length > 0
                        && assignedDocs.map((f, j) => (
                          <Grid item md={12} pr={1}>
                            <DefaultDocumentCard
                              key={`evaluation-document-${j}`}
                              id={f.id}
                              candidateId={uuid}
                              description={f.document.description}
                              title={f.document.name}
                              uploadedSub={f.file_url !== undefined && f.file_url !== null
                                ? t('uploaded', { keyPrefix: 'common' }) : undefined}
                              expanded={false}
                              fileUrl={f.file_url}
                              filesLimit={f.document.uploads_number}
                              maxFilesSize={f.document.max_size}
                              changeFunc={(files) => {
                                handleUploadDocument(files, f.id);
                              }}
                              removeFunc={() => {
                                handleDeleteDocument(f.id);
                              }}
                            />
                          </Grid>
                        ))}
                      <Grid item md={6} pr={1} />
                    </>
                  )}
                </>
              )}
              {selectedTab === 5 && (
                <>
                  <Grid item md={3}>
                    <FormField
                      label={t('flight-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('flight_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.flight_date)}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormField
                      label={t('expected-starting-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('expected_starting_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.expected_starting_date)}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormField
                      label={t('actual-starting-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('actual_starting_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.actual_starting_date)}
                    />
                  </Grid>
                </>
              )}
              {selectedTab === 6 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('rejected-on')}
                      type="date"
                      onChange={(event) => {
                        setValue('rejected_on', event.target.value);
                      }}
                      value={defaultValue(recruitment.rejected_on)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormSelect
                      label={t('rejected-by')}
                      placeholder={t('rejected-by')}
                      options={rejectBy}
                      value={
                        findSelectValue(rejectBy, recruitment.offer_rejected_by)
                      }
                      onChange={(event) => {
                        setValue('offer_rejected_by', event.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormSelect
                      label={t('stage')}
                      options={stages}
                      value={
                        findSelectValue(stages, recruitment.rejected_in_stage)
                      }
                      onChange={(e) => {
                        setValue('rejected_in_stage', e.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={6} />
                  <Grid item md={12}>
                    <SoftEditor
                      value={recruitment.rejection_notes}
                      onChange={(newContent) => {
                        setValue('rejection_notes', newContent);
                      }}
                    />
                  </Grid>
                  <Grid item md={12} />
                </>
              )}
              <Grid item md={12}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '3px',
                  }}
                  spacing={3}
                >
                  <Grid item>
                    <SoftButton
                      variant="gradient"
                      color="error"
                      size="small"
                      onClick={handleDelete}
                    >
                      {t('delete', { keyPrefix: 'common' })}
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={handleSave}
                    >
                      {t('save', { keyPrefix: 'common' })}
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </div>
      )}
      {!showApplication && Object.keys(recruitments).length > 0
        && recruitments.map((a, i) => (
          <div key={`application${i}`}>
            <ApplicationPositionCard
              position={a}
              openFunction={(e) => handleShowApplication(e, a.id)}
            />
            <Divider />
          </div>
        ))}
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SoftBox>
  );
}

export default JobApplications2;
