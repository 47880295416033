import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  checkSimilarCandidates,
  fetchCandidateProfile,
  setCurrentSubView,
  setProfile,
  setSimilarCandidates,
} from 'reducers/candidatesSlice';
import { fetchCitizenships, fetchCountries, fetchLanguages } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Components
import FormField from 'layouts/components/FormField';
import FormMaskInput from 'layouts/components/FormMaskInput';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { checkPermission, findSelectValue, getCandidateUUID } from 'Util';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import AddIcon from '@mui/icons-material/Add';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  profile: state.candidate.profile,
  languages: state.common.languages,
  countries: state.common.countries,
  citizenships: state.common.citizenships,
});

function BasicInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.basic-info' });
  const {
    currentUser,
    editing,
    profile,
    languages,
    countries,
    citizenships,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [candidateCitizenships, setCandidateCitizenships] = useState([]);
  const [selectedCitizenship, setSelectedCitizenship] = useState(null);
  const [availableCitizenships, setAvailableCitizenships] = useState([]);

  useEffect(() => {
    dispatch(setCurrentSubView('basic-info'));
    dispatch(fetchLanguages());
    dispatch(fetchCountries());
    dispatch(fetchCitizenships());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    if (!editing && uuid === undefined) {
      let check = false;

      if (profile.first_name !== undefined) {
        if (profile.first_name.length >= 2) {
          check = true;
        }
      }

      if (profile.last_name !== undefined) {
        if (profile.last_name.length >= 2) {
          check = true;
        }
      }
      if (check) {
        dispatch(
          checkSimilarCandidates({
            firstName: profile.first_name,
            lastName: profile.last_name,
          }),
        );
      } else {
        dispatch(setSimilarCandidates([]));
      }
    }
  }, [profile, dispatch, editing, uuid]);

  useEffect(() => {
    if (profile.candidate_citizenships !== undefined) {
      if (citizenships !== undefined && citizenships.length > 0) {
        setCandidateCitizenships(profile.candidate_citizenships);
        setAvailableCitizenships(citizenships.filter(
          (c) => !profile.candidate_citizenships
            // eslint-disable-next-line no-underscore-dangle
            .some((cc) => c.id === cc.country_id && cc._destroy === undefined),
        ));
      }
    } else {
      setAvailableCitizenships(citizenships);
    }
  }, [citizenships, profile.candidate_citizenships]);

  const setValue = (key, value) => {
    dispatch(
      setProfile(
        {
          ...profile,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleAddCitizenship = () => {
    setValue('candidate_citizenships', profile.candidate_citizenships
      .concat({ country_id: selectedCitizenship }));
  };

  const handleRemoveCitizenship = (e, id) => {
    e.preventDefault();

    if (id !== undefined) {
      setValue(
        'candidate_citizenships',
        profile.candidate_citizenships.map((obj) => {
          if (obj.id === id) {
            return {
              ...obj,
              _destroy: true,
            };
          }
          return obj;
        }),
      );
    }
  };

  const preferredMethod = [
    {
      value: 1,
      label: t('email'),
    },
    {
      value: 2,
      label: t('phone'),
    },
    {
      value: 3,
      label: t('whatsapp'),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card id="basic-info" sx={{ overflow: 'visible' }}>
          <SoftBox p={3}>
            <SoftTypography variant="h5">{t('basic-info')}</SoftTypography>
          </SoftBox>
          <SoftBox component="form" pb={3} px={3}>
            <Grid
              container
              spacing={3}
              direction="row"
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              {checkPermission('PCBN', currentUser) && (
                <>
                  <Grid item md={6}>
                    <FormField
                      label={t('first-name', { keyPrefix: 'common' })}
                      value={profile.first_name ? profile.first_name : ''}
                      error={!profile.first_name || profile.first_name === ''}
                      onChange={(e) => { setValue('first_name', e.target.value); }}
                      readOnly={checkPermission('PCBN-RO', currentUser)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label={t('middle-name', { keyPrefix: 'common' })}
                      value={profile.middle_name ? profile.middle_name : ''}
                      onChange={(e) => { setValue('middle_name', e.target.value); }}
                      readOnly={checkPermission('PCBN-RO', currentUser)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      label={t('last-name', { keyPrefix: 'common' })}
                      value={profile.last_name ? profile.last_name : ''}
                      error={!profile.last_name || profile.last_name === ''}
                      onChange={(e) => { setValue('last_name', e.target.value); }}
                      readOnly={checkPermission('PCBN-RO', currentUser)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      label={t('preferred-name')}
                      value={profile.preferred_name ? profile.preferred_name : ''}
                      onChange={(e) => { setValue('preferred_name', e.target.value); }}
                      readOnly={checkPermission('PCBN-RO', currentUser)}
                    />
                  </Grid>
                </>
              )}
              {checkPermission('PCBD', currentUser) && (
                <Grid item md={4}>
                  <FormField
                    label={t('birthdate')}
                    type="date"
                    placeholder={t('select-date')}
                    value={profile.dob ? profile.dob : ''}
                    onChange={(e) => { setValue('dob', e.target.value); }}
                    readOnly={checkPermission('PCBD-RO', currentUser)}
                  />
                </Grid>
              )}
              {checkPermission('PCBS', currentUser) && (
                <Grid item md={4}>
                  <FormMaskInput
                    label={t('ssn')}
                    mask="999-99-9999"
                    maskPlaceholder="*"
                    value={profile.ssn ? profile.ssn : ''}
                    onChange={(e) => { setValue('ssn', e.target.value); }}
                    readOnly={checkPermission('PCBS-RO', currentUser)}
                  />
                </Grid>
              )}
              {checkPermission('PCBL', currentUser) && (
                <Grid item md={4}>
                  <FormSelect
                    label={t('native-language')}
                    options={languages}
                    value={findSelectValue(languages, profile.language_id)}
                    onChange={(e) => { setValue('language_id', e.value); }}
                    readOnly={checkPermission('PCBL-RO', currentUser)}
                  />
                </Grid>
              )}
              {checkPermission('PCBO', currentUser) && (
                <Grid item md={4}>
                  <FormSelect
                    label={t('country-of-origin')}
                    options={countries}
                    value={findSelectValue(countries, profile.country_id)}
                    error={!profile.country_id || profile.country_id === ''}
                    onChange={(e) => { setValue('country_id', e.value); }}
                    readOnly={checkPermission('PCBO-RO', currentUser)}
                  />
                </Grid>
              )}
              {checkPermission('PCBR', currentUser) && (
                <Grid item md={4}>
                  <FormSelect
                    label={t('country-of-residence')}
                    options={countries}
                    value={findSelectValue(countries, profile.residence_country_id)}
                    onChange={(e) => { setValue('residence_country_id', e.value); }}
                    readOnly={checkPermission('PCBR-RO', currentUser)}
                  />
                </Grid>
              )}
              {checkPermission('PCBC', currentUser) && (
                <Grid item md={12}>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Grid item md={5}>
                      <FormSelect
                        label={t('citizenship')}
                        options={availableCitizenships}
                        onChange={(e) => {
                          setSelectedCitizenship(e.value);
                        }}
                        readOnly={checkPermission('PCBC-RO', currentUser)}
                      />
                    </Grid>
                    <Grid item md={1} mt={3}>
                      {!checkPermission('PCBC-RO', currentUser) && (
                        <IconButton
                          aria-label="add"
                          onClick={handleAddCitizenship}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item md={5} mt={3}>
                      <List>
                        {candidateCitizenships.map((c, i) => (
                          <div
                            key={`citizenship-${i}`}
                            style={{
                              // eslint-disable-next-line no-underscore-dangle
                              display: (c._destroy !== undefined ? 'none' : 'block'),
                            }}
                          >
                            <ListItem
                              secondaryAction={
                                !checkPermission('PCBC-RO', currentUser) && (
                                  <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon onClick={(e) => handleRemoveCitizenship(e, c.id)} />
                                  </IconButton>
                                )
                            }
                            >
                              <ListItemText
                                primary={findSelectValue(citizenships, c.country_id).label}
                              />
                            </ListItem>
                          </div>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
      {checkPermission('CAPCM', currentUser) && (
        <Grid item xs={12} md={12}>
          <Card id="contact-method" sx={{ overflow: 'visible' }}>
            <SoftBox p={3}>
              <SoftTypography variant="h5">{t('contact-method')}</SoftTypography>
            </SoftBox>
            <SoftBox component="form" pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormSelect
                    label={t('preferred-contact-method')}
                    options={preferredMethod}
                    value={findSelectValue(preferredMethod, profile.contact_method)}
                    onChange={(e) => {
                      setValue('contact_method', e.value);
                    }}
                    readOnly={checkPermission('PCM-RO', currentUser)}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

export default BasicInfo;
