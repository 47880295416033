import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardContent from '@mui/material/CardContent';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';

// Utils
import { defaultValue, findSelectValue, ngnSubjects } from 'Util';

function DefaultNclexAssessmentCard(
  {
    i,
    c,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.nclex' });

  const [expanded, setExpanded] = useState(true);
  const [credentialMenu, setCredentialMenu] = useState(null);

  const openCredentialMenu = (event) => setCredentialMenu(event.currentTarget);
  const closeCredentialMenu = () => setCredentialMenu(null);
  const handleExpand = () => {
    closeCredentialMenu();
    setExpanded(!expanded);
  };

  const topics = ngnSubjects(t);

  const testTypes = [
    {
      value: 1,
      label: t('customizable'),
    },
    {
      value: 2,
      label: t('cat'),
    },
    {
      value: 3,
      label: t('readiness-assessment'),
    },
  ];

  const results = [
    {
      value: 'Very High',
      label: t('very-high'),
    },
    {
      value: 'High',
      label: t('high'),
    },
    {
      value: 'Borderline',
      label: t('borderline'),
    },
    {
      value: 'Low',
      label: t('low'),
    },
  ];

  const readiness = [
    {
      value: 1,
      label: t('ready-to-schedule-nclex'),
    },
    {
      value: 2,
      label: t('not-ready-to-schedule-nclex'),
    },
  ];

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openCredentialMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${defaultValue(c.date)} - ${t('nclex-assessment')}`}
      />
      <Menu
        anchorEl={credentialMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(credentialMenu)}
        onClose={closeCredentialMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={2.5}>
              <FormField
                label={t('date')}
                type="date"
                error={c.date ? false
                  : !c.date || c.date === ''}
                onChange={(event) => {
                  setValue('date', event.target.value, i);
                }}
                value={defaultValue(c.date)}
              />
            </Grid>
            <Grid item md={3.5}>
              <FormSelect
                label={t('type-test')}
                options={testTypes}
                onChange={(e) => {
                  setValue('type_test', e.value, i);
                }}
                value={findSelectValue(testTypes, c.type_test)}
              />
            </Grid>
            <Grid item md={2.5}>
              <FormField
                label={t('score')}
                onChange={(event) => {
                  setValue('score', event.target.value, i);
                }}
                value={defaultValue(c.score)}
              />
            </Grid>
            <Grid item md={3.5}>
              <FormSelect
                label={t('result')}
                options={results}
                onChange={(e) => {
                  setValue('result', e.value, i);
                }}
                value={findSelectValue(results, c.result)}
              />
            </Grid>
            <Grid item md={6}>
              <FormSelect
                label={t('challenging-topic')}
                options={topics}
                onChange={(e) => {
                  setValue('challenging_topic_id', e.value, i);
                }}
                value={findSelectValue(topics, c.challenging_topic_id)}
              />
            </Grid>
            <Grid item md={6}>
              <FormSelect
                label={t('nclex-readiness')}
                options={readiness}
                error={!c.nclex_readiness_id || c.nclex_readiness_id === ''}
                onChange={(e) => {
                  setValue('nclex_readiness_id', e.value, i);
                }}
                value={findSelectValue(readiness, c.nclex_readiness_id)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

DefaultNclexAssessmentCard.propTypes = {
  i: PropTypes.number.isRequired,
  c: PropTypes.shape(
    {
      date: PropTypes.string,
      type_test: PropTypes.number,
      score: PropTypes.number,
      result: PropTypes.string,
      questions: PropTypes.number,
      challenging_topic_id: PropTypes.number,
      nclex_readiness_id: PropTypes.number,
      id: PropTypes.number,
    },
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultNclexAssessmentCard;
