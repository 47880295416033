// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Other components
import PhoneInput from 'react-phone-number-input';
import 'assets/styles/phone-input.css';

function FormPhoneInput({
  label = ' ', readOnly = false, error = false, ...rest
}) {
  return (
    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SoftTypography>
      </SoftBox>
      <PhoneInput
        readOnly={readOnly}
        style={error ? { 'border-color': '#fd5c70' } : {}}
        {...rest}
      />
    </SoftBox>
  );
}

// Typechecking props for FormPhoneInput
FormPhoneInput.propTypes = {
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  error: PropTypes.bool,
};

export default FormPhoneInput;
