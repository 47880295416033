// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Custom styles for the SidenavCollapse
import { useSoftUIController } from 'context';
import {
  collapseArrow, collapseIcon, collapseIconBox, collapseItem, collapseText,
} from './styles/sidenavCollapse';

// Soft UI Dashboard PRO React context

function SidenavCollapse({
  color = 'info',
  active = false,
  noCollapse = false,
  children = false,
  open = false,
  icon,
  name,
  ...rest
}) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;

  return (
    <>
      <ListItem component="li">
        <SoftBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}
          >
            {typeof icon === 'string' ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
          />

          <Icon
            sx={(theme) => collapseArrow(theme, {
              noCollapse, transparentSidenav, miniSidenav, open,
            })}
          >
            expand_less
          </Icon>
        </SoftBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  active: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  noCollapse: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
};

export default SidenavCollapse;
