function advisorColumns(t) {
  return (
    [
      {
        caption: t('first-name', { keyPrefix: 'common' }),
        field: 'first_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('last-name', { keyPrefix: 'common' }),
        field: 'last_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('e-mail', { keyPrefix: 'candidates.contact-info' }),
        field: 'default_email',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('phone', { keyPrefix: 'candidates.contact-info' }),
        field: 'default_phone',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('country-of-origin', { keyPrefix: 'candidates.basic-info' }),
        field: 'country_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('current-advisor', { keyPrefix: 'candidates.basic-info' }),
        field: 'current_advisor_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('other-advisors', { keyPrefix: 'candidates.basic-info' }),
        field: 'other_advisors_names',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('country-of-residence', { keyPrefix: 'candidates.basic-info' }),
        field: 'residence_country_name',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('recruiter', { keyPrefix: 'candidates.recruiters' }),
        field: 'recruiter',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('status', { keyPrefix: 'common' }),
        field: 'candidate_status_name',
        visible: false,
        allowFixing: true,
      },
    ]);
}

export default advisorColumns;
