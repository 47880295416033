import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// Reducer functions
import {
  cancelExpense,
  createExpense,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  updateExpense,
  clearExpense,
} from 'reducers/expenseSlice';

// @material-ui core components
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// Functions
import Swal from 'sweetalert2';
import { errorsToString } from 'Util';

const selector = (state) => ({
  expenseInfo: state.expense.expenseInfo,
  editing: state.expense.editing,
  created: state.expense.created,
  saved: state.expense.saved,
  deleted: state.expense.deleted,
  failed: state.expense.failed,
  errors: state.expense.errors,
  removeData: state.expense.removeData,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'expenses' });
  const {
    expenseInfo, editing, created, saved, deleted, failed, errors, removeData,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [openBackdrop, setOpenBackdrop] = useState(false);

  if (created === true) {
    setOpenBackdrop(false);
    dispatch(setCreated(false));
    dispatch(clearExpense());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('expense-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/expenses', { replace: true });
    });
  }
  if (saved === true) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));
    dispatch(clearExpense());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('expense-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/expenses', { replace: true });
    });
  }
  if (deleted === true) {
    setOpenBackdrop(false);
    dispatch(setDeleted(false));
    dispatch(clearExpense());

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('expense-deleted-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/expenses', { replace: true });
    });
  }
  if (failed === true) {
    setOpenBackdrop(false);
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete-it', { keyPrefix: 'common' }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenBackdrop(true);
        const data = { id };
        dispatch(cancelExpense(data));
        navigate('/expenses', { replace: true });
      }
    });
  };

  const handleSave = () => {
    if (expenseInfo.changed === true) {
      setOpenBackdrop(true);
      const data = {
        date: expenseInfo.date,
        memo: expenseInfo.memo,
        candidateId: expenseInfo.candidate_id,
        vendorId: expenseInfo.vendor_id,
        paymentMethodId: expenseInfo.payment_method_id,
        expenseCategoryId: expenseInfo.expense_category_id,
        expenseItems: expenseInfo.expense_items,
        payBy: expenseInfo.pay_by,
        reimbursable: expenseInfo.reimbursable,
        billed: expenseInfo.billed,
      };

      if (editing === true) {
        data.id = id;

        removeData.items.forEach((r) => {
          data.expenseItems = data.expenseItems.concat(r);
        });

        dispatch(updateExpense(data));
      } else {
        dispatch(createExpense(data));
      }
    }
  };

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SoftBox mr={1}>
            <SoftButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SoftButton>
          </SoftBox>
        )}
        <SoftBox mr={1}>
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SoftBox>
  );
}

export default SavePanel;
