import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Reducer functions
import { fetchCandidateProfile } from 'reducers/candidatesSlice';

// @mui material components
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';

// Layout components
import SimpleInfoCard from 'layouts/components/Cards/InfoCards/SimpleInfoCard';
import LoadingSpinner from 'layouts/components/LoadingSpinner';

const selector = (state) => ({
  profile: state.candidate.profile,
  status: state.candidate.status,
});

function Advisor(
  {
    uuid,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'widgets' });
  const {
    profile,
    status,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [advisor, setAdvisor] = useState('');

  useEffect(() => {
    if (uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
    }
  }, [dispatch, uuid]);

  useEffect(() => {
    if (profile && profile.advisors) {
      const adv = profile.advisors.find((a) => a.default === true);
      if (adv) {
        setAdvisor(adv.name);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (status === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [status, t]);

  return (
    <div>
      {isLoading && (<LoadingSpinner />)}
      {!isLoading && (
        <SimpleInfoCard
          title={t('advisor')}
          value={advisor}
          icon={<PersonPinCircleIcon fontSize="large" />}
        />
      )}
    </div>
  );
}

Advisor.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default Advisor;
