import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';

import SoftTypography from 'components/SoftTypography';
import SoftBadgeDot from 'components/SoftBadgeDot';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function RecruitmentPositionCard({
  position,
  client,
  location = '',
  date = '',
  tags = [],
  openFunction = null,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  return (
    <Card>
      <CardActionArea onClick={openFunction}>
        <Grid
          container
          direction="row"
          p={2}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Grid item md={9}>
            <SoftTypography
              fontWeight="bold"
              variant="h5"
            >
              {position}
            </SoftTypography>
          </Grid>
          <Grid item md={3}>
            <Grid
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                {location !== '' && (
                  <SoftTypography
                    variant="subtitle1"
                    fontWeight="regular"
                  >
                    <FontAwesomeIcon icon={faLocationDot} />
                    {' '}
                    {location}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={9}>
            <SoftTypography
              variant="subtitle1"
              fontWeight="regular"
            >
              {client}
            </SoftTypography>
          </Grid>
          <Grid item md={3}>
            <Grid
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <SoftTypography
                  variant="subtitle2"
                  fontWeight="regular"
                >
                  {`${t('opened-on')}: ${date}`}
                </SoftTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Stack spacing={2} direction="row">
              {Object.keys(tags).length > 0
                && tags.map((tag, i) => (
                  <div key={`tag${i}`}>
                    <SoftBadgeDot badgeContent={tag} size="md" />
                  </div>
                ))}
            </Stack>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

RecruitmentPositionCard.propTypes = {
  position: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  location: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  date: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  tags: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/require-default-props
  openFunction: PropTypes.func,
};

export default RecruitmentPositionCard;
