import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';

function SimpleInfoCard({
  color = 'info', icon, title, value = '',
}) {
  return (
    <Card sx={{ display: 'flex', padding: '5px' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={3}>
          <SoftBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            bgColor={color}
            color="white"
            width="3rem"
            height="3rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"
          >
            {icon}
          </SoftBox>
        </Grid>
        <Grid item md={9}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={12}>
              <SoftTypography variant="body2">{title}</SoftTypography>
            </Grid>
            <Grid item md={12}>
              <SoftTypography variant="h5">{value}</SoftTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

SimpleInfoCard.propTypes = {
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SimpleInfoCard;
