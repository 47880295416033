import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setCoaching } from 'reducers/candidatesSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// Components
import FormSelect from 'layouts/components/FormSelect';
import CoachingSessionCard from 'layouts/components/Cards/CoachingSessionCard';
import NashInstituteCard from 'layouts/components/Cards/NashInstituteCard';

// Functions
import { findSelectValue } from 'Util';

const selector = (state) => ({
  currentUser: state.auth.user,
  currentSubView: state.candidate.currentSubView,
  goToSubView: state.candidate.goToSubView,
  editing: state.candidate.editing,
  coaching: state.candidate.coaching,
});

function General() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.coaching' });
  const {
    coaching,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const statuses = [
    {
      value: 1,
      label: t('first-attempt'),
    },
    {
      value: 2,
      label: t('retaker'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setCoaching(
        {
          ...coaching,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const setCSValue = (key, value, i) => {
    dispatch(
      setCoaching(
        {
          ...coaching,
          candidate_coaching_sessions: coaching.candidate_coaching_sessions.map(
            (obj, index) => {
              if (index === i) {
                return {
                  ...obj,
                  [key]: value,
                  changed: true,
                };
              }
              return obj;
            },
          ),
          changed: true,
        },
      ),
    );
  };

  const setNIValue = (key, value, i) => {
    dispatch(
      setCoaching(
        {
          ...coaching,
          candidate_coaching_nash_institutes: coaching.candidate_coaching_nash_institutes.map(
            (obj, index) => {
              if (index === i) {
                return {
                  ...obj,
                  [key]: value,
                  changed: true,
                };
              }
              return obj;
            },
          ),
          changed: true,
        },
      ),
    );
  };

  const handleAddCoaching = (e) => {
    e.preventDefault();
    dispatch(
      setCoaching(
        {
          ...coaching,
          candidate_coaching_sessions: coaching.candidate_coaching_sessions.concat({
            id: undefined,
            enrollment_date: null,
            expiration_date: null,
            active: false,
          }),
          changed: true,
        },
      ),
    );
  };

  const handleRemoveCoaching = (i) => {
    if (coaching.candidate_coaching_sessions[i].id !== undefined) {
      setCSValue('_destroy', true, i);
    } else {
      dispatch(
        setCoaching(
          {
            ...coaching,
            candidate_coaching_sessions: [
              ...coaching.candidate_coaching_sessions.slice(0, i),
              ...coaching.candidate_coaching_sessions.slice(i + 1),
            ],
            changed: true,
          },
        ),
      );
    }
  };

  const handleAddNashInstitute = (e) => {
    e.preventDefault();
    dispatch(
      setCoaching(
        {
          ...coaching,
          candidate_coaching_nash_institutes: coaching.candidate_coaching_nash_institutes.concat({
            id: undefined,
            enrollment_date: null,
            expiration_date: null,
            active: false,
          }),
          changed: true,
        },
      ),
    );
  };

  const handleRemoveNashInstitute = (i) => {
    if (coaching.candidate_coaching_nash_institutes[i].id !== undefined) {
      setNIValue('_destroy', true, i);
    } else {
      dispatch(
        setCoaching(
          {
            ...coaching,
            candidate_coaching_nash_institutes: [
              ...coaching.candidate_coaching_nash_institutes.slice(0, i),
              ...coaching.candidate_coaching_nash_institutes.slice(i + 1),
            ],
            changed: true,
          },
        ),
      );
    }
  };

  return (
    <SoftBox component="form">
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <SoftTypography variant="h4" fontWeight="medium">
              {t('general-information')}
            </SoftTypography>
          </Grid>
        </Grid>
      </SoftBox>
      <Grid container spacing={1} mt={1}>
        <Grid item md={6}>
          <FormSelect
            label={t('status')}
            options={statuses}
            error={!coaching.status || coaching.status === ''}
            onChange={(e) => setValue('status', e.value)}
            value={findSelectValue(statuses, coaching.status)}
          />
        </Grid>
        <Grid item md={6} />
      </Grid>
      <Grid container spacing={2} mt={1} mb={1}>
        <Grid item md={12}>
          <SoftTypography variant="h5" fontWeight="medium">
            {t('nash-institute')}
          </SoftTypography>
        </Grid>
        <Grid item md={12} mb={1}>
          <SoftBox display="flex" justifyContent="flex-end">
            <SoftButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleAddNashInstitute}
            >
              {t('new-registration')}
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={1}>
        <Grid item md={12}>
          {coaching.candidate_coaching_nash_institutes
            && coaching.candidate_coaching_nash_institutes
              // eslint-disable-next-line no-underscore-dangle
              .filter((ni) => ni._destroy === undefined)
              .map((ni, i) => (
                <div key={`ni-${i}`} style={{ paddingBottom: '10px' }}>
                  <NashInstituteCard
                    i={i}
                    ni={ni}
                    setValue={setNIValue}
                    removeFunc={handleRemoveNashInstitute}
                  />
                </div>
              ))}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} mb={1}>
        <Grid item md={12}>
          <SoftTypography variant="h5" fontWeight="medium">
            {t('coaching')}
          </SoftTypography>
        </Grid>
        <Grid item md={12} mb={1}>
          <SoftBox display="flex" justifyContent="flex-end">
            <SoftButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleAddCoaching}
            >
              {t('new-coaching')}
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={1}>
        <Grid item md={12}>
          {coaching.candidate_coaching_sessions
            && coaching.candidate_coaching_sessions
              // eslint-disable-next-line no-underscore-dangle
              .filter((cs) => cs._destroy === undefined)
              .map((cs, i) => (
                <div key={`cs-${i}`} style={{ paddingBottom: '10px' }}>
                  <CoachingSessionCard
                    i={i}
                    session={cs}
                    setValue={setCSValue}
                    removeFunc={handleRemoveCoaching}
                  />
                </div>
              ))}
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default General;
